
import Dashboard from "layouts/dashboard";
import Vendor from "layouts/vendor";
import Order from "layouts/order";
import VendorOrder from "layouts/vendorOrder";
import Product from "layouts/product";
import Orderfetch from "layouts/ordersFetch";
import CompletedOrder from "layouts/completedOrder";
import Summary from "layouts/summary";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Role from "layouts/role";
import User from "layouts/user";
import Country from "layouts/country";
import State from "layouts/state";
import City from "layouts/city";
import ShippedProduct from "layouts/shippedProduct"
import ShippedProductList from "layouts/shippedProduct/shippedProductlist";
import Center from "layouts/center";
import Delivery from "layouts/delivery";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Icon from "@mui/material/Icon";
import Currency from "layouts/currency";

export const routesAdmin = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    
  },
  {
    type: "collapse",
    name: "Vendor",
    key: "vendor",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/vendor",
    component: <Vendor />,
    
  },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/order",
    component: <Order />,
    
  },
  {
    type: "collapse",
    name: "Vendor Order",
    key: "vendorOrder",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/vendorOrder",
    component: <VendorOrder />,
    
  },
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/product",
    component: <Product />,
    
  },
  {
    type: "hidden",
    name: "Role",
    key: "role",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/role",
    component: <Role />,
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user",
    component: <User />,
  },
  {
    type: "collapse",
    name: "Currency",
    key: "currency",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/currency",
    component: <Currency />,
    
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
   
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
   
  },
  {
    type: "collapse",
    name: "Center",
    key: "center",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/center",
    component: <Center />,
  },
  {
    type: "hidden",
    name: "Delivery Service Provider",
    key: "delivery",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/delivery",
    component: <Delivery Service Provider />,
  },
  {
    type: "collapse",
    name: "Orders Fetch",
    key: "ordersFetch",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/ordersFetch",
    component: <Orderfetch />,
  },
  {
    type: "collapse",
    name: "Shipped Product",
    key: "shippedProduct",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/shippedProduct",
    component: <ShippedProduct />,
  },
  {
    type: "hidden",
    name: "ShippedProductList",
    key: "shippedProductlist",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/shippedProductlist",
    component: <ShippedProductList />,
  },
  {
    type: "collapse",
    name: "Completed Orders",
    key: "completedOrder",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/completedOrder",
    component: <CompletedOrder />,
  },
  {
    type: "collapse",
    name: "Summary",
    key: "summary",
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: "/summary",
    component: <Summary />,
  },
  
  // {
  //   type: "collapse",
  //   name: "Country",
  //   key: "country",
  //   icon: <Icon fontSize="small">public</Icon>,
  //   route: "/country",
  //   component: <Country />,
  // },
  // {
  //   type: "collapse",
  //   name: "State",
  //   key: "state",
  //   icon: <Icon fontSize="small">map</Icon>,
  //   route: "/state",
  //   component: <State />,
  // },
  // {
  //   type: "collapse",
  //   name: "City",
  //   key: "city",
  //   icon: <Icon fontSize="small">location_city</Icon>,
  //   route: "/city",
  //   component: <City />,
  // },
];

export const routesOwner = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" sx={{ color: 'black' }} >dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    
  },
  {
    type: "collapse",
    name: "Vendor",
    key: "vendor",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/vendor",
    component: <Vendor />,
    
  },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/order",
    component: <Order />,
    
  },
  {
    type: "collapse",
    name: "Vendor Order",
    key: "vendorOrder",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/vendorOrder",
    component: <VendorOrder />,
    
  },
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/product",
    component: <Product />,
    
  },
  {
    type: "hidden",
    name: "Role",
    key: "role",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/role",
    component: <Role />,
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user",
    component: <User />, 
  },
  {
    type: "collapse",
    name: "Currency",
    key: "currency",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/currency",
    component: <Currency />,
    
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    invisible: true
    // Mark this route as private
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    invisible: true
    
  },
  {
    type: "collapse",
    name: "Center",
    key: "center",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/center",
    component: <Center />,
  },
  {
    type: "hidden",
    name: "Delivery Service Provider",
    key: "delivery",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/delivery",
    component: <Delivery Service Provider />,
  },
  {
    type: "collapse",
    name: "Orders Fetch",
    key: "ordersFetch",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/ordersFetch",
    component: <Orderfetch />,
  },
  {
    type: "collapse",
    name: "Shipped Product",
    key: "shippedProduct",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/shippedProduct",
    component: <ShippedProduct />,
  },
  {
    type: "hidden",
    name: "Shipped Product List",
    key: "shippedproductlist",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/shippedProductlist",
    component: <ShippedProductList />,
  },
  {
    type: "collapse",
    name: "Completed Orders",
    key: "completedOrder",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/completedOrder",
    component: <CompletedOrder />,
  },
  {
    type: "collapse",
    name: "Summary",
    key: "summary",
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: "/summary",
    component: <Summary />,
  },
  // {
  //   type: "collapse",
  //   name: "Country",
  //   key: "country",
  //   icon: <Icon fontSize="small">public</Icon>,
  //   route: "/country",
  //   component: <Country />,
  // },
  // {
  //   type: "collapse",
  //   name: "State",
  //   key: "state",
  //   icon: <Icon fontSize="small">map</Icon>,
  //   route: "/state",
  //   component: <State />,
  // },
  // {
  //   type: "collapse",
  //   name: "City",
  //   key: "city",
  //   icon: <Icon fontSize="small">location_city</Icon>,
  //   route: "/city",
  //   component: <City />,
  // },
];
export const routesDasher = [
  {
    type: "collapse",
    name: "Vendor Order",
    key: "vendorOrder",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/vendorOrder",
    component: <VendorOrder />,
    
  },
  {
    type: "collapse",
    name: "Shipped Product",
    key: "shippedProduct",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/shippedProduct",
    component: <ShippedProduct />,
  },
  {
    type: "hidden",
    name: "Shipped Product List",
    key: "shippedproductlist",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/shippedProductlist",
    component: <ShippedProductList />,
  },  
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  }
];



import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  useMediaQuery,
  Modal, Grid,
  CircularProgress,
  IconButton,
  Tooltip
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import api from "../../service/api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Swiper, SwiperSlide } from 'swiper/react';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { TbListDetails } from 'react-icons/tb';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import CloseIcon from '@mui/icons-material/Close';
import { BiEdit } from 'react-icons/bi';
import { FaChartBar } from "react-icons/fa";
import OrderChart from './orderchart';  // Import the SalesChart component
import Cookies from 'js-cookie';
import { LuFilterX } from "react-icons/lu";

const monthArray = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];


const Summary = () => {
  const currentDate = new Date().toISOString().split('T')[0];

  const [grouping, setGrouping] = useState('month');
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenChart, setModalOpenChart] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [year, month] = currentDate.split('-');

  const firstDayOfMonth = `${year}-${month}-01`;
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(currentDate);

  useEffect(() => {
    const savedStartDate = Cookies.get('startDateSummary') || firstDayOfMonth;
    const savedEndDate = Cookies.get('endDateSummary') || currentDate;
    setStartDate(savedStartDate);
    setEndDate(savedEndDate);
  }, [firstDayOfMonth, currentDate]);

  // Handle start date change
  const handleInputStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    Cookies.set('startDateSummary', newStartDate, { expires: 7 }); // Set cookie for 7 days
  };

  // Handle end date change
  const handleInputEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    Cookies.set('endDateSummary', newEndDate, { expires: 7 }); // Set cookie for 7 days
  };


  useEffect(() => {
    // Retrieve cookie value on component mount
    const savedQuery = Cookies.get('sortGroup');
    if (savedQuery) {
      setGrouping(savedQuery);
    }
  }, []);

  const handleGroupChange = (event) => {
    const newSearchQuery = event.target.value;
    setGrouping(newSearchQuery);
    // Set cookie with the new search query
    Cookies.set('sortGroup', newSearchQuery, { expires: 7 }); // Expires in 7 days
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/OrderByShipping?SDate=${startDate}&EDate=${endDate}&dateFlag=${true}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });
      setSalesData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchOrders = async () => {
  //   // if (!orderStartDate || !orderEndDate) {
  //   //   toast.error('Both start and end dates are required!');
  //   //   return;
  //   // }

  //   try {
  //     const response = await api.get(`/OrderByShipping?SDate=${orderStartDate}&EDate=${orderEndDate}&dateFlag=${checked}&searchString=${searchQuery}`, {
  //       headers: {
  //         "Authorization": `Bearer ${localStorage.getItem("token")}`
  //       }
  //     });

  //     setOrders(response.data);
  //   } catch (error) {
  //     console.error("Error refreshing order list:", error);
  //   }
  // };

  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate]);




  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getDateRanges(period) {
    const today = new Date(endDate);
    const start = new Date(startDate);
    const ranges = [];

    if (period === 'week') {
      let startOfWeek = new Date(start);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

      while (startOfWeek <= today) {
        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        ranges.push({
          startDate: formatDate(startOfWeek),
          endDate: formatDate(endOfWeek > today ? today : endOfWeek),
        });

        startOfWeek.setDate(startOfWeek.getDate() + 7);
      }
    } else if (period === 'month') {
      const startMonth = new Date(startDate);
      const endMonth = new Date(endDate);

      while (startMonth <= endMonth) {
        const startOfMonth = new Date(startMonth.getFullYear(), startMonth.getMonth(), 1);
        const endOfMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() + 1, 0);

        ranges.push({
          startDate: formatDate(startOfMonth),
          endDate: formatDate(endOfMonth > endDate ? endDate : endOfMonth),
        });

        startMonth.setMonth(startMonth.getMonth() + 1);
      }
    } else if (period === 'year') {
      const startYear = new Date(startDate);
      const endYear = new Date(endDate);

      while (startYear.getFullYear() <= endYear.getFullYear()) {
        const startOfYear = new Date(startYear.getFullYear(), 0, 1);
        const endOfYear = new Date(startYear.getFullYear(), 11, 31);

        ranges.push({
          startDate: formatDate(startOfYear),
          endDate: formatDate(endOfYear > endDate ? endDate : endOfYear),
        });

        startYear.setFullYear(startYear.getFullYear() + 1);
      }
    }

    return ranges;
  }

  // Helper function to get the month name from a month number
  function getMonthName(monthNumber) {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthNumber - 1]; // monthNumber is 1-based, so subtract 1
  }

  function groupOrdersByPeriod(salesData, period) {
    const dateRanges = getDateRanges(period);
    const groupedOrders = {};

    dateRanges.forEach(range => {
      const key = period === 'week' ? `${range.startDate} to ${range.endDate}` :
        // period === 'month' ? range.startDate.slice(0, 7) :
        period === 'month' ? getMonthName(parseInt(range.startDate.split('-')[1], 10)) + ` ${range.startDate.split('-')[0]}` :
          period === 'year' ? `${range.startDate.slice(0, 4)}` :
            null;

      if (key) {
        groupedOrders[key] = {
          totalSales: 0,
          totalCOGS: 0,
          totalProfit: 0,
          totalGrossMargin: 0,
          items: [],
          orderCount: 0,
          AOV: 0,
        };
      }
    });

    salesData.forEach(order => {
      const orderDate = new Date(order.orderDate);
      const orderYear = orderDate.getFullYear();
      const orderMonth = String(orderDate.getMonth() + 1).padStart(2, '0');
      const orderDay = orderDate.getDate();

      const salesAmount = parseFloat((order.orderPrice - (order.totalDiscount + order.totalRefund)));
      const cogsAmount = (order.purchasePrice);
      const profit = (salesAmount - (order.purchasePrice + order.shippingCost) + (order.shopifyShippingcost + order.shopifyTipAmount));
      const grossMargin = (salesAmount - (order.purchasePrice + order.shippingCost) + (order.shopifyShippingcost + order.shopifyTipAmount));

      let key = null;
      if (period === 'week') {
        let weekStart = new Date(orderDate);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        let weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);

        key = `${formatDate(weekStart)} to ${formatDate(weekEnd > new Date() ? new Date() : weekEnd)}`;
      } else if (period === 'month') {
        key = getMonthName(parseInt(orderMonth, 10)) + ` ${orderYear}`;
      } else if (period === 'year') {
        key = `${orderYear}`;
      }

      if (key && groupedOrders[key]) {
        const group = groupedOrders[key];
        group.totalSales += salesAmount;
        group.totalCOGS += cogsAmount;
        group.totalProfit += profit;
        group.totalGrossMargin += grossMargin;
        group.items.push(order);
        group.orderCount++;
        group.AOV = (group.totalSales / group.orderCount).toFixed(2);
      }
    });

    return Object.entries(groupedOrders).map(([key, metrics]) => ({
      period: key,
      ...metrics
    }));
  }


  const finalData = groupOrdersByPeriod(salesData, grouping);
  console.log(finalData);

  const prepareCsvData = (finalData) => {
    const csvData = [];

    Object.keys(finalData).forEach((groupKey) => {
      const group = finalData[groupKey];
      csvData.push({
        "Group": group.period,
        "Total Sales": group.totalSales.toFixed(2),
        "Total COGS": group.totalCOGS.toFixed(2),
        "Total Profit": group.totalProfit.toFixed(2),
        "Total Gross Margin": group.totalGrossMargin.toFixed(2),
        "COGS Percentage": ((group.totalCOGS / group.totalSales) * 100).toFixed(2),
        "Gross Margin Percentage": ((group.totalGrossMargin / group.totalSales) * 100),
        "Net Margin": ((group.totalProfit / group.totalSales) * 100),
        "AOV": group.AOV,
        "Order Count": group.orderCount,
      });

      group.items.forEach((item) => {
        csvData.push({
          "Order No": item.orderName,
          "Customer Name": item.fullName,
          "Tracking": item.trackingId,
          "Delivery": item.deliveryProvider,
          "Order Price": (item.orderPrice - (item.totalDiscount + item.totalRefund)),
          "Shopify ShippingCost": (item.shopifyShippingcost),
          "Shopify Tip": (item.shopifyTipAmount),
          "Shipping Cost": (item.shippingCost),
          "COGS": item.purchasePrice,
          "Profit": (
            (item.orderPrice - (item.totalDiscount + item.totalRefund)) -
            (item.purchasePrice + item.shippingCost) +
            (item.shopifyShippingcost + item.shopifyTipAmount)
          ).toFixed(2),
          "Order Date": item.orderDate,
          "Dispatch Date": item.dispatch_Date,
        });
      });
    });

    return csvData;
  };

  const csvData = prepareCsvData(finalData);

  const handleActionClick = (items) => {
    setChartData(items);  // Set the data for the chart
    setModalOpenChart(true);   // Open the modal
  };

  const clearSortOrder = () => {
    Cookies.remove('startDateSummary');
    Cookies.remove('endDateSummary');
    Cookies.remove('sortGroup');
    setStartDate(firstDayOfMonth);
    setEndDate(currentDate);
  };

  const role = localStorage.getItem('role');

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
      <FormControl
          sx={{
            margin: '0 10px 0 10px',
            width: isMobile ? "100%" : "auto",
            maxWidth: 120,
            margin: isMobile ? "8px 0" : "4px",
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45',
              },
            },
          }}
        >
          <InputLabel id="grouping" style={{ color: '#3e3e45' }}>Group By</InputLabel>
          <Select
            labelId="grouping"
            value={grouping}
            //onChange={(e) => setGrouping(e.target.value)}
            onChange={handleGroupChange}
            label="Group By"
            sx={{
              width: isMobile ? "100%" : "130px",
              borderRadius: '0.375rem',
              padding: "10px 0px",
              marginBottom: isMobile ? "0px" : "0",
            }}
          >

            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
            <MenuItem value="year">Yearly</MenuItem>
          </Select>
        </FormControl>

        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <TextField
            type="date"
            label="Start Date"
            required
            value={startDate}
            onChange={handleInputStartDateChange}
            //onChange={(e) => setStartDate(e.target.value)}
            variant="outlined"
            sx={{
              width: 'fit-content',
              borderRadius: '20px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000',
                },
                '&:hover fieldset': {
                  borderColor: '#000',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
              },
            }}
          />
          <TextField
            type="date"
            label="End Date"
            required
            value={endDate}
            onChange={handleInputEndDateChange}
            //onChange={(e) => setEndDate(e.target.value)}
            variant="outlined"
            sx={{
              marginLeft: '10px',
              width: 'fit-content',
              borderRadius: '20px',
              marginLeft: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000',
                },
                '&:hover fieldset': {
                  borderColor: '#000',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={fetchOrders}
          style={{
            marginLeft: '10px', color: 'white',fontWeight:'400',textTransform: 'none', backgroundColor: '#3e3e45'
          }}
        >
          Filter
        </Button>

        {role === 'Owner' &&(
        <Button
          style={{ color: "white", backgroundColor: "green" }}
          className="btn btn-success"
          variant="contained"
          sx={{ marginLeft: isMobile ? "0" : "10px", marginTop: isMobile ? "10px" : "0", marginBottom: isMobile ? "10px" : "0", width: isMobile ? "40%" : "auto" }}
        >
          <CSVLink data={csvData} filename={`order-details-${moment().format('YYYY-MM-DD')}.csv`} style={{ fontWeight:'400',textTransform: 'none',textDecoration: "none", color: "white" }}>
            Export to CSV
          </CSVLink>
        </Button>
          )}

        <Tooltip title="Clear">
          <Button
            onClick={clearSortOrder}
            variant="contained"
            style={{ color: 'white', backgroundColor: '#3e3e45', marginLeft: isMobile ? '0' : 'auto', }}
          // sx={{
          //   marginLeft: isMobile ? '0' : 'auto',
          //   marginTop: isMobile ? '10px' : '0',
          //   marginBottom: isMobile ? '10px' : '0',
          //   width: isMobile ? '40%' : 'auto',
          // }}
          >
            <LuFilterX style={{ fontSize: '20px' }} />
          </Button>
        </Tooltip>

      </Box>
      <>
        {isMobile ? (
          Object.entries(finalData).map(([group, { period, totalSales, totalCOGS, totalProfit, totalGrossMargin, grossMarginPercentage, netMargin, cogsPercentage, AOV, items }]) => (
            <Card
              key={group.period}
              onClick={() => {
                setModalOpen(true);
                setOrderDetails(items);
              }}
              sx={{
                cursor: 'pointer',
                background: 'white',
                border: '0px solid black',
                margin: '15px',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" component="h2" sx={{ marginBottom: '16px' }}>
                  Sr No: {parseInt(group) + 1}.
                </Typography>
                <Typography variant="h6" component="h2" sx={{ marginBottom: '16px' }}>
                  Group: {period}
                </Typography>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Total Sales:</strong>
                  <span>₹{totalSales.toFixed(2)}</span>
                </Box>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Total COGS:</strong>
                  <span>₹{totalCOGS.toFixed(2)}</span>
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                  <strong style={{ marginRight: '16px' }}>COGS (%):</strong>
                  <span>{(totalSales === 0 || totalSales === undefined)
                    ? 0
                    : ((totalCOGS / totalSales) * 100).toFixed(2)}%</span>
                </Box>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Total Profit:</strong>
                  <span>₹{totalProfit.toFixed(2)}</span>
                </Box>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Total Gross Margin:</strong>
                  <span>₹{totalGrossMargin.toFixed(2)}</span>
                </Box>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Gross Margin (%):</strong>
                  <span>{(totalSales === 0 || totalSales === undefined)
                    ? 0
                    : ((totalGrossMargin / totalSales) * 100).toFixed(2)}%</span>
                </Box>
                <Box sx={{ marginBottom: '8px' }}>
                  <strong style={{ marginRight: '16px' }}>Net Margin:</strong>
                  <span>{(totalSales === 0 || totalSales === undefined)
                    ? 0
                    : ((totalProfit / totalSales) * 100).toFixed(2)}%</span>
                </Box>

                <Box sx={{ marginBottom: '16px' }}>
                  <strong style={{ marginRight: '16px' }}>Average Order Value (AOV):</strong>
                  <span>₹{AOV}</span>
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                  <strong style={{ marginRight: '16px' }}>Action:</strong>
                  <IconButton
                    style={{ color: 'black', marginRight: '10px' }}
                    onClick={() => {
                      setModalOpen(true);
                      setOrderDetails(items);
                    }}
                  >
                    <TbListDetails />
                  </IconButton>
                  <IconButton onClick={() => handleActionClick(items)}>
                    <FaChartBar />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          ))
        ) : (
          // <TableContainer component={Paper}>
          //   <Table>
          //     <TableBody>
          //       <TableRow sx={{ backgroundColor: '#3e3e45' }}>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Sr No
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Group
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Total Sales
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Total COGS
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           COGS (%)
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Total Profit
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Total Gross Margin
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Gross Margin (%)
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Net Margin
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           AOV
          //         </TableCell>
          //         <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
          //           Actions
          //         </TableCell>
          //       </TableRow>

          //       {Object.entries(finalData).map(([group, { period, totalSales, totalCOGS, totalProfit, totalGrossMargin, grossMarginPercentage, netMargin, cogsPercentage, AOV, items }]) => (
          //         <TableRow key={group.period}>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>{parseInt(group) + 1}.</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>{period}</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalSales.toFixed(2)}</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalCOGS.toFixed(2)}</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
          //             ? 0
          //             : (((totalCOGS / totalSales) * 100).toFixed(2))}%</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalProfit.toFixed(2)}</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalGrossMargin.toFixed(2)}</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
          //             ? 0
          //             : ((totalGrossMargin / totalSales) * 100).toFixed(2)}%</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
          //             ? 0
          //             : ((totalProfit / totalSales) * 100).toFixed(2)}%</TableCell>
          //           <TableCell align="center" style={{ fontWeight: '500' }}>₹{AOV}</TableCell>
          //           <TableCell align="center">
          //             <Tooltip title="View Details">
          //               <IconButton

          //                 style={{ color: 'black', marginRight: '10px' }}
          //                 onClick={() => {
          //                   setModalOpen(true);
          //                   setOrderDetails(items);
          //                 }}
          //               >
          //                 <TbListDetails />
          //               </IconButton>
          //             </Tooltip>
          //             <Tooltip title="View Graph">
          //               <IconButton onClick={() => handleActionClick(items)}>
          //                 <FaChartBar />
          //               </IconButton>
          //             </Tooltip>
          //           </TableCell>
          //         </TableRow>
          //       ))}
          //     </TableBody>
          //   </Table>
          // </TableContainer>
          <TableContainer component={Paper} sx={{mt: 1, maxHeight: 600, overflow: 'auto', scrollbarWidth: 'none' }}>
            <Table stickyHeader>
              <TableBody>
                <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Sr No
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Group
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Total Sales
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Total COGS
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    COGS (%)
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Total Profit
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Total Gross Margin
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Gross Margin (%)
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Net Margin
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    AOV
                  </TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">
                    Actions
                  </TableCell>
                </TableRow>

                {Object.entries(finalData).map(([group, { period, totalSales, totalCOGS, totalProfit, totalGrossMargin, grossMarginPercentage, netMargin, cogsPercentage, AOV, items }]) => (
                  <TableRow key={group.period}>
                    <TableCell align="center" style={{ fontWeight: '500' }}>{parseInt(group) + 1}.</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>{period}</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalSales.toFixed(2)}</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalCOGS.toFixed(2)}</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
                      ? 0
                      : (((totalCOGS / totalSales) * 100).toFixed(2))}%</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalProfit.toFixed(2)}</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>₹{totalGrossMargin.toFixed(2)}</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
                      ? 0
                      : ((totalGrossMargin / totalSales) * 100).toFixed(2)}%</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>{(totalSales === 0 || totalSales === undefined)
                      ? 0
                      : ((totalProfit / totalSales) * 100).toFixed(2)}%</TableCell>
                    <TableCell align="center" style={{ fontWeight: '500' }}>₹{AOV}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="View Details">
                        <IconButton

                          style={{ color: 'black', marginRight: '10px' }}
                          onClick={() => {
                            setModalOpen(true);
                            setOrderDetails(items);
                          }}
                        >
                          <TbListDetails />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Graph">
                        <IconButton onClick={() => handleActionClick(items)}>
                          <FaChartBar />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>


      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 1600,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 2,
            maxHeight: isMobile ? '80vh' : '90vh',
            overflowY: "auto",
            scrollbarWidth: 'none'
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="body1" style={{ margin: '5px', fontWeight: '500', fontSize: '25px' }} component="div">
            Order Details
          </Typography>

          {isMobile ? (
            <Swiper spaceBetween={10} slidesPerView={1}>
              {orderDetails.map((order, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      padding: 2,
                      border: '1px solid #ddd',
                      borderRadius: '10px',
                      mb: 2,
                      bgcolor: '#f9f9f9', // Light background for better contrast
                    }}
                  >
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>Name:</strong>
                      <span>{order.fullName}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>Order No:</strong>
                      <span>{order.orderName}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>Total Weight:</strong>
                      <span>{order.totalWeight}<span style={{ fontSize: 'medium' }}>gm</span></span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>Selling Price:</strong>
                      <span>₹{(order.orderPrice - (order.totalDiscount + order.totalRefund)).toFixed(2)}</span>
                    </Box>
                    <Box>
                      <strong style={{ marginRight: '16px' }}>Shopify Shipping Cost:</strong>
                      <span>₹{(order.shopifyShippingcost || 0).toFixed(2)}</span>
                    </Box>
                    <Box>
                      <strong style={{ marginRight: '16px' }}>Shopify Tip:</strong>
                      <span>₹{(order.shopifyTipAmount || 0).toFixed(2)}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>COGS:</strong>
                      <span>₹{order.purchasePrice || 0}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '8px' }}>Shipping Cost:</strong>
                      <span>₹{order.shippedCost || 0}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '24px' }}>Tracking Id:</strong>
                      <span>{order.trackingId}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '24px' }}>Address:</strong>
                      <span>{order.fullAddress}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '24px' }}>Delivery:</strong>
                      <span>{order.deliveryProvider}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '16px' }}>Profit:</strong>
                      <span>₹{(
                        (order.orderPrice - (order.totalDiscount + order.totalRefund)) -
                        (order.purchasePrice + order.shippingCost) +
                        (order.shopifyShippingcost + order.shopifyTipAmount)
                      ).toFixed(2)}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '24px' }}>Order Date:</strong>
                      <span>{moment(order.orderDate).format('ll')}</span>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <strong style={{ marginRight: '24px' }}>Dispatch Date:</strong>
                      <span> {order.dispatch_Date ? moment(order.dispatch_Date).format('ll') : '-'}</span>
                    </Box>
                    {/* <Box>
                        <strong style={{ marginRight: '16px' }}>Action:</strong>
                        <IconButton
                          style={{ color: 'black', marginLeft: '10px' }}
                          onClick={() => handleOpenDetailsModal(order)}
                        >
                          <TbListDetails />
                        </IconButton>
                        <Tooltip title="Update Order">
                          <IconButton
                            style={{ color: 'green', marginLeft: '10px' }}
                            onClick={() => handleOpenUpdateModal(order)}
                          >
                            <BiEdit />
                          </IconButton>
                        </Tooltip>
                      </Box> */}
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto', scrollbarWidth: 'none' }}>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                      <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                      <TableCell style={{ fontWeight: '500', color: 'white' }}>Full Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Order No</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Order Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Dispatch Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Selling Price</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shopify Shippingcost</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shopify Tip</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>COGS</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shipping Cost</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Tracking Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Delivery</TableCell>
                      <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Profit/Loss</TableCell>
                      {/* <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Actions</TableCell> */}
                    </TableRow>
                    {orderDetails.map((order, index) => (
                      <TableRow
                        key={index}
                        style={{
                          background: order && order.shippingCost !== null
                            ? 'white'
                            : 'lightpink'
                        }}
                      >
                        <TableCell style={{ fontWeight: "500" }}>
                          {index + 1}.
                        </TableCell>
                        <TableCell style={{ fontWeight: '500' }}>
                          {order.fullName}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          {order.orderName}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          {moment(order.orderDate).format('ll') || '-'}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          {order.dispatch_Date ? moment(order.dispatch_Date).format('ll') : '-'}
                        </TableCell>

                        <TableCell style={{ fontWeight: '500' }} align="center">₹{(order.orderPrice - (order.totalDiscount + order.totalRefund)).toFixed(2)}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">₹{(order.shopifyShippingcost || 0).toFixed(2)}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">₹{(order.shopifyTipAmount || 0).toFixed(2)}</TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          ₹{order.purchasePrice || 0}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          ₹{order.shippingCost || 0}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          {order.trackingId}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: '500' }}>
                          {order.deliveryProvider}
                        </TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">
                          ₹{(
                            (order.orderPrice - (order.totalDiscount + order.totalRefund)) -
                            (order.purchasePrice + order.shippingCost) +
                            (order.shopifyShippingcost + order.shopifyTipAmount)
                          ).toFixed(2)}
                        </TableCell>
                        {/* <TableCell align="center" style={{ fontWeight: '500' }}>
                            <IconButton
                              style={{ color: 'black', marginLeft: '10px' }}
                              onClick={() => handleOpenDetailsModal(order)}
                            >
                              <TbListDetails />
                            </IconButton>
                            <Tooltip title="Update Order">
                              <IconButton
                                style={{ color: 'green', marginLeft: '10px' }}
                                onClick={() => handleOpenUpdateModal(order)}
                              >
                                <BiEdit />
                              </IconButton>
                            </Tooltip>
                          </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

        </Box>
      </Modal>

      <Modal open={modalOpenChart} onClose={() => setModalOpenChart(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 1500,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 2,
            maxHeight: isMobile ? '80vh' : '90vh',
            overflowY: "auto",
            scrollbarWidth: 'none'
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={() => {
              setModalOpenChart(false);
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="body1" style={{ margin: '5px', fontWeight: '500', fontSize: '25px' }} component="div" >
            Order Bar Chart
          </Typography>
          <OrderChart data={chartData} />
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default Summary;

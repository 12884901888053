import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { BiEdit } from 'react-icons/bi';
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Card,
    CardContent,
    Modal,
    Grid,
    Container,
    IconButton,
    Box,
    Pagination
} from '@mui/material';
import MDBox from "../../components/MDBox";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Footer from "layouts/authentication/components/Footer";
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { LuFilterX } from "react-icons/lu";
import useMediaQuery from '@mui/material/useMediaQuery';

const Center = () => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
         phoneNo: null,
         email: null,
    });

    const [errors, setErrors] = useState({
        name: '',
        //phoneNo: '',
        //email: '',
    });

    const [centers, setCenters] = useState([]);
    const [operationFlag, setOperationFlag] = useState(0);
    const [editCenterId, setEditCenterId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const fetchCenters = async () => {
        try {
            const response = await api.get('/CenterList', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            setCenters(response.data); // Set the centers in state
        } catch (error) {
            console.error('Error fetching centers:', error);
        }
    };

    useEffect(() => {
        // Fetch centers when the component mounts
        fetchCenters();
    }, []);

    const handleSignIn = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        const newErrors = { ...errors };

        // Validate form fields
        if (!formData.name) {
            newErrors.name = 'Name is required';
            formIsValid = false;
        } else {
            newErrors.name = '';
        }

        // if (!formData.phoneNo) {
        //     newErrors.phoneNo = 'Phone No is required';
        //     formIsValid = false;
        // } else {
        //     newErrors.phoneNo = '';
        // }

        // if (!formData.email) {
        //     newErrors.email = 'Email is required';
        //     formIsValid = false;
        // } else {
        //     newErrors.email = '';
        // }

        setErrors(newErrors);

        if (formIsValid) {
            try {
                let response;
                if (operationFlag === 0) {
                    response = await api.post(`/AddEditCenter?flag=${operationFlag}`, formData, {
                        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                    });
                    console.log('Center added successfully:', response.data);
                    toast.success('Center added successfully!');
                } else if (operationFlag === 1) {
                    response = await api.post(`/AddEditCenter?flag=${operationFlag}`, formData, {
                        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                    });
                    console.log('Center updated successfully:', response.data);
                    toast.success('Center updated successfully!');
                }
                setOperationFlag(0); // Reset to add mode
                setOpenModal(false); // Close modal after submission
                await fetchCenters(); // Refetch centers to update the list
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred. Please try again.');
            }
        }

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditCenter = (centerId) => {
        // Find the center by its ID
        const centerToEdit = centers.find(center => center.id === centerId);

        // If the center is found, populate the form data with its details
        if (centerToEdit) {
            setFormData({
                id: centerToEdit.id,
                name: centerToEdit.name, // Make sure property names match
                // phoneNo: centerToEdit.phoneNo, // Make sure property names match
                // email: centerToEdit.email, // Make sure property names match
            });

            // Set the ID of the center being edited
            setEditCenterId(centerId);
            setOperationFlag(1);
            setOpenModal(true); // Open the modal for editing
        } else {
            console.error("Center not found for editing");
        }
    };

    const handleOpenModal = () => {
        resetForm();
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        resetForm();
        setOpenModal(false);
    };

    const resetForm = () => {
        setFormData({
            name: '',
            phoneNo: null,
            email: null,
        });
        setErrors({
            name: '',
            // phoneNo: '',
            // email: '',
        });
        setOperationFlag(0);
        setEditCenterId(null);
    };

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchCenter');
        if (savedQuery) {
            setSearchTerm(savedQuery);
        }
    }, []);

    const handleSearchTermChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchTerm(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchCenter', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    // const handleSearchTermChange = (event) => {
    //     setSearchTerm(event.target.value);
    // };

    const filteredCenters = centers.filter(center =>
        center.name.toLowerCase().toString().includes(searchTerm.toLowerCase().toString())
    );


    const pageCount = Math.ceil(filteredCenters.length / itemsPerPage);
    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    const clearSortOrder = () => {
        setSearchTerm('');
        Cookies.remove('searchCurrency'); // Clear the cookie
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : ' top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }} />
            <div className="p-3">
                {/* <MDBox
                    
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <Typography variant="h5" style={{ color: 'white' }} component="h2">
                        Center Management
                    </Typography>
                </MDBox> */}

                <div className="d-flex justify-content-between" style={{ margin: '15px' }}>
                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            value={searchTerm}
                            size="medium"
                            onChange={handleSearchTermChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{fontSize:'20px'}}>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                width: '100%', // Make the search field responsive
                                maxWidth: 250, // Max width for larger screens
                                marginRight: '10px', // Right margin for spacing
                                marginBottom: '5px', // Bottom margin for spacing
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0.375rem', // Rounded corners
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3e3e45', // Color when focused
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '1rem', // Adjust label size
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3e3e45',fontWeight: '400', marginBottom: '8px', textTransform: 'none' }}
                            onClick={handleOpenModal}
                        >
                            Add Center
                        </Button>
                        <Tooltip title="Clear">
                            <Button
                                onClick={clearSortOrder}
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#3e3e45' }}
                                sx={{
                                    marginLeft: isMobile ? '0' : 'auto',
                                    marginTop: isMobile ? '10px' : '0',
                                    marginBottom: isMobile ? '10px' : '0',
                                    width: isMobile ? '40%' : 'auto',
                                }}
                            >
                                <LuFilterX style={{ fontSize: '20px' }} />
                            </Button>
                        </Tooltip>

                    </div>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>

                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Name</TableCell>
                            {/* <TableCell>Phone No</TableCell>
                             <TableCell>Email</TableCell> */}
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
                        </TableRow>
                        {filteredCenters.map((center, index) => (
                            <TableRow key={center.id}>
                                <TableCell style={{ fontWeight: "500", width: '15%' }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                                <TableCell style={{ fontWeight: '500' }} >{center.name}</TableCell>
                                {/* <TableCell>{center.phoneNo}</TableCell>
                                 <TableCell>{center.email}</TableCell> */}
                                <TableCell>
                                    <Tooltip title="Edit Center">
                                        <IconButton
                                            style={{ color: '#13a531', alignContent: 'center' }}
                                            onClick={() => handleEditCenter(center.id)}
                                        >
                                            <BiEdit />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {pageCount > 1 && (
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color='primary'
                        sx={{
                            '& .MuiPaginationItem-root.Mui-selected': {
                                color: '#fff',
                                background: 'linear-gradient(195deg, #42424a, #191919)',
                            },
                        }}
                    />
                )}
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card style={{ padding: '20px', margin: '100px auto', maxWidth: '600px' }}>
                    <Typography variant="h5" align="center">
                        {operationFlag === 1 ? 'Edit Center' : 'Add Center'}
                    </Typography>
                    <form onSubmit={handleSignIn}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            variant="outlined"
                            margin="normal"
                        />
                        {/* <TextField
                            fullWidth
                            label="Phone No"
                            name="phoneNo"
                            value={formData.phoneNo}
                            onChange={handleInputChange}
                            error={!!errors.phoneNo}
                            helperText={errors.phoneNo}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            variant="outlined"
                            margin="normal"
                        /> */}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', textTransform: 'none' }} type="submit">
                                {operationFlag === 1 ? 'Update Center' : 'Add Center'}
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal>
            {/* <Footer dark /> */}
        </DashboardLayout>
    );
};

export default Center;

import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { BiEdit } from 'react-icons/bi';
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Card,
    CardContent,
    Modal,
    Grid,
    Container,
    IconButton,
    Box,
    Pagination
} from '@mui/material';
import MDBox from "../../components/MDBox";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Footer from "layouts/authentication/components/Footer";

const Delivery = () => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        // phoneNo: '',
        // email: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        // phoneNo: '',
        // email: '',
    });

    const [Deliverys, setDeliverys] = useState([]);
    const [operationFlag, setOperationFlag] = useState(0);
    const [editDeliveryId, setEditDeliveryId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchDeliverys = async () => {
        try {
            const response = await api.get('/DeliveryList' , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
            setDeliverys(response.data); // Set the Deliverys in state
        } catch (error) {
            console.error('Error fetching Deliverys:', error);
        }
    };

    useEffect(() => {
        // Fetch Deliverys when the component mounts
        fetchDeliverys();
    }, []);

    const handleSignIn = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        const newErrors = { ...errors };

        // Validate form fields
        if (!formData.name) {
            newErrors.name = 'Name is required';
            formIsValid = false;
        } else {
            newErrors.name = '';
        }

        // if (!formData.phoneNo) {
        //     newErrors.phoneNo = 'Phone No is required';
        //     formIsValid = false;
        // } else {
        //     newErrors.phoneNo = '';
        // }

        // if (!formData.email) {
        //     newErrors.email = 'Email is required';
        //     formIsValid = false;
        // } else {
        //     newErrors.email = '';
        // }

        setErrors(newErrors);

        if (formIsValid) {
            try {
                let response;
                if (operationFlag === 0) {
                    response = await api.post(`/AddEditDelivery?flag=${operationFlag}`, formData , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
                    console.log('Delivery added successfully:', response.data);
                } else if (operationFlag === 1) {
                    // Include editDeliveryId when updating Delivery
                    response = await api.post(`/AddEditDelivery?flag=${operationFlag}`, formData , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
                    console.log('Delivery updated successfully:', response.data);
                }
                setOperationFlag(0); // Reset to add mode
                setOpenModal(false); // Close modal after submission
                await fetchDeliverys(); // Refetch Deliverys to update the list
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditDelivery = (DeliveryId) => {
        // Find the Delivery by its ID
        const DeliveryToEdit = Deliverys.find(Delivery => Delivery.id === DeliveryId);

        // If the Delivery is found, populate the form data with its details
        if (DeliveryToEdit) {
            setFormData({
                id: DeliveryToEdit.id,
                name: DeliveryToEdit.name, // Make sure property names match
                // phoneNo: DeliveryToEdit.phoneNo, // Make sure property names match
                // email: DeliveryToEdit.email, // Make sure property names match
            });

            // Set the ID of the Delivery being edited
            setEditDeliveryId(DeliveryId);
            setOperationFlag(1);
            setOpenModal(true); // Open the modal for editing
        } else {
            console.error("Delivery not found for editing");
        }
    };

    const handleOpenModal = () => {
        resetForm();
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        resetForm();
        setOpenModal(false);
    };

    const resetForm = () => {
        setFormData({
            name: '',
            // phoneNo: '',
            // email: '',
        });
        setErrors({
            name: '',
            // phoneNo: '',
            // email: '',
        });
        setOperationFlag(0);
        setEditDeliveryId(null);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredDeliverys = Deliverys.filter(Delivery =>
        Delivery.name.toLowerCase().toString().includes(searchTerm.toLowerCase().toString())
    );

    const pageCount = Math.ceil(filteredDeliverys.length / itemsPerPage);
    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="p-3">
                {/* <MDBox

                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <Typography variant="h5" style={{ color: 'white' }} component="h2">
                        Delivery Management
                    </Typography>
                </MDBox> */}

                <div className="d-flex justify-content-between" style={{ margin: '15px' }}>
                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            value={searchTerm}
                            size="medium"
                            onChange={handleSearchTermChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{fontSize:'20px'}}>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                width: '100%', // Make the search field responsive
                                maxWidth: 250, // Max width for larger screens
                                marginRight: '20px', // Right margin for spacing
                                marginBottom: '5px', // Bottom margin for spacing
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0.375rem', // Rounded corners
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3e3e45', // Color when focused
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '1rem', // Adjust label size
                                },
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3e3e45', marginBottom: '10px', textTransform: 'none' }}
                            onClick={handleOpenModal}
                        >
                            Add Delivery
                        </Button></div>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableBody>
                    <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
                    <TableCell  style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                    
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Name</TableCell>
                            {/* <TableCell>Phone No</TableCell>
                             <TableCell>Email</TableCell> */}
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
                        </TableRow>
                        {filteredDeliverys.map((Delivery,index) => (
                            <TableRow key={Delivery.id}>
                                <TableCell style={{ fontWeight: '500',width:'15%'}}>{(currentPage - 1)* itemsPerPage + index+1}.</TableCell>
                                <TableCell style={{ fontWeight: '500' }} >{Delivery.name}</TableCell>
                                {/* <TableCell>{Delivery.phoneNo}</TableCell>
                                 <TableCell>{Delivery.email}</TableCell> */}
                                <TableCell>
                                <Tooltip title="Edit">
                                    <IconButton
                                        style={{ color: '#13a531', alignContent: 'Delivery' }}
                                        onClick={() => handleEditDelivery(Delivery.id)}
                                    >
                                        <BiEdit />
                                    </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {pageCount > 1 && (
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handleChangePage}
                            color='primary'
                            sx={{
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    color: '#fff',
                                    background: 'linear-gradient(195deg, #42424a, #191919)',
                                },
                            }}
                        />
                    )}
                </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card style={{ padding: '20px', margin: '100px auto' , maxWidth: '600px' }}>
                    <Typography variant="h5" align="Delivery">
                        {operationFlag === 1 ? 'Edit Delivery' : 'Add Delivery'}
                    </Typography>
                    <form onSubmit={handleSignIn}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            variant="outlined"
                            margin="normal"
                        />

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', textTransform: 'none'}} type="submit">
                                {operationFlag === 1 ? 'Update Delivery' : 'Add Delivery'}
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal>
            {/* <Footer dark /> */}
        </DashboardLayout>
    );
};

export default Delivery;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   FormControl, Button
// } from '@mui/material';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import api from "../../../service/api";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// function Basic() {
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [errors, setErrors] = useState({ email: "", password: "" });

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validatePassword = (password) => {
//     return password.length >= 6; // Example: password must be at least 6 characters
//   };

//   const handleSignIn = async (event) => {
//     event.preventDefault();
//     let formIsValid = true;
//     const newErrors = { ...errors };

//     // Validate email field
//     if (!formData.email) {
//       newErrors.email = "Email is required";
//       formIsValid = false;
//     } else if (!validateEmail(formData.email)) {
//       newErrors.email = "Invalid email format";
//       formIsValid = false;
//     } else {
//       newErrors.email = "";
//     }


//     // Validate password field
//     if (!formData.password) {
//       newErrors.password = "Password is required";
//       formIsValid = false;
//     } else if (!validatePassword(formData.password)) {
//       newErrors.password = "Password must be at least 6 characters long";
//       formIsValid = false;
//     } else {
//       newErrors.password = "";
//     }

//     setErrors(newErrors);

//     if (formIsValid) {
//       try {
//         const response = await api.post("/Login", formData);
//         console.log("Login successful:", response.data);
//         toast.success("Login successful!");

//         localStorage.setItem("id", response.data.id);
//         localStorage.setItem("role", response.data.role);
//         localStorage.setItem("token", response.data.token);
//         localStorage.setItem("name", response.data.name);

//         // Redirect based on the role
//         if (response.data.role === "Admin") {
//           window.location.href = "/dashboard";
//         } else if (response.data.role === "Owner") {
//           window.location.href = "/dashboard";
//         } else if (response.data.role === "Dasher") {
//           window.location.href = "/vendorOrder";
//         }
//         // No redirection for other roles
//       } catch (error) {
//         console.error("Login error:", error);
//         toast.error("Login failed. Please check your Email or Password.");
//       }
//     } else {
//       console.error("Login error:");
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   return (
//     <BasicLayout image={bgImage}>
//       <ToastContainer
//       style={{ 
//         position: isMobile ? 'top-right': 'top-right',
//         width: isMobile ? '80%' : '25%', 
//         fontSize: isMobile ? '12px' : '15px' 
//       }}
//     />
//       <Card>
//         <MDBox
//           variant="gradient"
//           bgColor="dark"
//           borderRadius="lg"
//           coloredShadow="info"

//           mx={2}
//           mt={-3}
//           p={2}
//           mb={1}
//           textAlign="center"
//         >
//           <h4 style={{color:'#fff'}}>Sign in to Rezili </h4>
//           <Link to="/"></Link>
//         </MDBox>
//         <MDBox pt={4} pb={3} px={3}>
//           <form onSubmit={handleSignIn}>
//             <MDBox mb={2}>
//               <MDInput
//                 type="email"
//                 label="Email"
//                 fullWidth
//                 onChange={handleInputChange}
//                 name="email"
//                 value={formData.email}
//               />
//               <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>{errors.email}</div>
//             </MDBox>
//             <MDBox mb={2}>
//               <MDInput
//                 type="password"
//                 label="Password"
//                 fullWidth
//                 onChange={handleInputChange}
//                 name="password"
//                 value={formData.password}
//               />
//               <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>{errors.password}</div>
//             </MDBox>
//             <MDBox mt={4} mb={1}>
//               <MDButton variant="gradient" color="dark" fullWidth type="submit">
//                 Sign in
//               </MDButton>
//             </MDBox>
//           </form>
//         </MDBox>
//       </Card>
//     </BasicLayout>
//   );
// }

// export default Basic;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FormControl, Button, Box
} from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../service/api";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Basic() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Example: password must be at least 6 characters
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };

    // Validate email field
    if (!formData.email) {
      newErrors.email = "Email is required";
      formIsValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email format";
      formIsValid = false;
    } else {
      newErrors.email = "";
    }


    // Validate password field
    if (!formData.password) {
      newErrors.password = "Password is required";
      formIsValid = false;
    } else if (!validatePassword(formData.password)) {
      newErrors.password = "Password must be at least 6 characters long";
      formIsValid = false;
    } else {
      newErrors.password = "";
    }

    setErrors(newErrors);

    if (formIsValid) {
      try {
        const response = await api.post("/Login", formData);
        console.log("Login successful:", response.data);
        toast.success("Login successful!");

        localStorage.setItem("id", response.data.id);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("name", response.data.name);

        // Redirect based on the role
        if (response.data.role === "Admin") {
          window.location.href = "/dashboard";
        } else if (response.data.role === "Owner") {
          window.location.href = "/dashboard";
        } else if (response.data.role === "Dasher") {
          window.location.href = "/vendorOrder";
        }
        // No redirection for other roles
      } catch (error) {
        console.error("Login error:", error);
        toast.error("Login failed. Please check your Email or Password.");
      }
    } else {
      console.error("Login error:");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <ToastContainer
        style={{
          position: isMobile ? 'top-right' : 'top-right',
          width: isMobile ? '80%' : '25%',
          fontSize: isMobile ? '12px' : '15px'
        }}
      />

      <Card
        sx={{
          width: '100%',
          maxWidth: 400,
          padding: 2,
          borderRadius: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Add slight transparency
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <h2 style={{ color: '#333', marginBottom: '1rem' }}>Sign In To Rezili</h2>
        <form onSubmit={handleSignIn} >
          <div style={{marginBottom:'16px'}}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              onChange={handleInputChange}
              name="email"
              value={formData.email}
            />
            <div style={{ color: 'red', fontSize: '0.75rem', marginTop: '0.25rem' }}>
              {errors.email}
            </div>
          </div>
          <div style={{marginBottom:'16px'}}>
            <MDInput
              type="password"
              label="Password"
              fullWidth
              onChange={handleInputChange}
              name="password"
              value={formData.password}
            />
            <div style={{ color: 'red', fontSize: '0.75rem', marginTop: '0.25rem' }}>
              {errors.password}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: '#3e3e45',
                justifyContent: 'center',
                width: '30%',
                fontSize: isMobile ? '12px' : '16px'
              }}
              type="submit"
            >
              Sign in
            </Button>
          </div>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

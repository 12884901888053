import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../service/api";
import Card from "@mui/material/Card";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Cover() {
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    roleId: "",
  });
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function fetchData() {
      try {
        const rolesResponse = await api.get("/GetRole");
        setRoles(rolesResponse.data);

        const usersResponse = await api.get("/GetUser");
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formIsValid = validateForm();

    if (formIsValid) {
      try {
        const response = await api.post("/Registration", formData);
        console.log("Registration successful:", response.data);
        toast.success("Registration successful!");

        localStorage.setItem("id", response.data.id);
        window.location.href = "/dashboard";
      } catch (error) {
        console.error("Registration error:", error);
        toast.error("Registration failed. Please check your details and try again.");
      }
    } else {
      toast.error("Please fill Forms .");
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    } else if (users.some(user => user.email === formData.email)) {
      newErrors.email = "Email is already registered";
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be exactly 10 digits";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    if (!formData.roleId) {
      newErrors.roleId = "Role selection is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <CoverLayout image={bgImage}>
     <ToastContainer
      style={{ 
        position: isMobile ? 'top-right': 'top-right',
        width: isMobile ? '80%' : '25%', 
        fontSize: isMobile ? '12px' : '15px' 
      }}
    />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <h4>Join us today to IndiaShopping</h4>
          <Link to="/"></Link>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox mb={1}>
              <MDInput
                type="text"
                label="First Name"
                fullWidth
                onChange={handleInputChange}
                value={formData.firstName}
                name="firstName"
              />
              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                {errors.firstName}
              </div>
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="text"
                label="Last Name"
                fullWidth
                onChange={handleInputChange}
                value={formData.lastName}
                name="lastName"
              />
              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                {errors.lastName}
              </div>
            </MDBox>
            <MDBox mb={1}>
              <FormControl fullWidth >
                <InputLabel  >
                  
                </InputLabel>
                <MDInput
                  type="email"
                  fullWidth
                  label="Email"
                  onChange={handleInputChange}
                  value={formData.email}
                  name="email"
                />
                <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                  {errors.email}
                </div>
              </FormControl>
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="number"
                label="Phone Number"
                fullWidth
                onChange={handleInputChange}
                value={formData.phoneNumber}
                name="phoneNumber"
              />
              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                {errors.phoneNumber}
              </div>
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={handleInputChange}
                value={formData.password}
                name="password"
              />
              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                {errors.password}
              </div>
            </MDBox>
            <MDBox mb={1}>
              <div className="form-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <FormControl fullWidth variant="outlined"  style={{ marginBottom: '10px' }}>
                  <Select
                    id="ddlRole"
                    onChange={handleInputChange}
                    name="roleId"
                    value={formData.roleId || ''}
                    displayEmpty
                    style={{ margin: '10px', padding: '10px 0px' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <>Select Role</>
                    </MenuItem>
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ color: "red", fontSize: "0.75rem", marginTop: "0.25rem" }}>
                  {errors.roleId}
                </div>
              </div>
            </MDBox>

            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign up
              </MDButton>
              <MDBox mt={2}  textAlign="center">
                <span>Already have an account? </span>
                <Link to="/authentication/sign-in">Sign in</Link>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;

import React, { useState, useEffect, useCallback } from 'react';
import api from '../../service/api';
import { Box, Button, TableContainer, Paper, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Modal, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableRow, TableCell, Typography, Pagination, IconButton, TextField, colors } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import MDBox from "../../components/MDBox"
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FcCancel } from "react-icons/fc";
import { IoMdCheckboxOutline } from "react-icons/io";
import Tooltip from '@mui/material/Tooltip';
import { LuFilterX } from "react-icons/lu";
import CloseIcon from '@mui/icons-material/Close';
import Footer from "layouts/authentication/components/Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../node_modules/swiper/swiper-bundle.min.css';
import '../../../node_modules/swiper/swiper.min.css';
import Cookies from 'js-cookie';

const VendorOrder = () => {
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [orderDetails, setOrderDetails] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState(''); // Set default sorting order to descending
    const itemsPerPage = 12;
    const [localOrderDetails, setLocalOrderDetails] = useState(orderDetails);
    const [filter, setFilter] = useState('all');
    //const [alert, setAlert] = useState({ show: false, message: '', type: 'success' });
    const [updatedOrderId, setUpdatedOrderId] = useState(null);
    const [sortOption, setSortOption] = useState('');
    //const [sort, setSort] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [formData, setFormData] = useState({
        id: '',
        purchasedQuantity: null,
        pricePerQty: null,
        productCost: null,
        weightPerQty: null,
        is_Purchased: null,
        productId: ''
    });

    useEffect(() => {
        setLocalOrderDetails(orderDetails);
    }, [orderDetails]);

    // const handleConfirm = () => {
    //     const filteredOrders = localOrderDetails.filter((orderDetail) => orderDetail.isPurchased === null);
    //     setLocalOrderDetails(filteredOrders);
    // };

    // const toggleHidePurchased = () => {
    //     setHidePurchased((prev) => !prev);
    // };

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await api.get('/GetUsers', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
                setUser(response.data);
                //setUser(currentUser);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
        fetchUserData();
    }, []);

    const fetchVendors = async () => {
        try {
            const userId = localStorage.getItem("id");
            const response = await api.get(`/AllVendorOrders?userId=${id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });

            setVendors(response.data);

        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };
    useEffect(() => {
        if (user) {
            fetchVendors();
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    const resetErrors = () => {
        setErrors({
            purchasedQuantity: false,
            productCost: false,
        });
    };

    const toggleModal = async (vendor) => {
        setSelectedVendor(vendor);
        setIsModalOpen((prevState) => {
            if (prevState) {
                resetErrors(); // Reset errors when closing the modal
            }
            return !prevState;
        });


        if (!isModalOpen) {
            try {
                setFormData({
                    id: '',
                    pricePerQty: null,
                    weightPerQty: null,
                    is_Purchased: true,
                    lock_Flag: true

                });
                const response = await api.post(`/OrderDetailsbyVendorId?vendorId=${vendor.id}`,
                    {}, // Pass an empty object for the request body if there is no body
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                setOrderDetails(response.data);
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        }
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        const newOrderDetails = [...orderDetails];

        // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
        newOrderDetails[index].purchasedQuantity = Math.max(0, Math.min(value, newOrderDetails[index].quantity));

        // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
        //newOrderDetails[index].productCost = Math.max(0, Math.min(value, newOrderDetails[index].calculateItemTotalPrice));
        // Update status based on purchased quantity
        const status = newOrderDetails[index].purchasedQuantity === newOrderDetails[index].quantity ? "Purchased" : "Pending";
        newOrderDetails[index].status = status;

        setOrderDetails(newOrderDetails);
        setFormData(prevState => ({
            ...prevState,
            purchasedQuantity: newOrderDetails[index].purchasedQuantity
        }));
    };

    const handlePriceChange = (e, index) => {

        const newOrders = [...localOrderDetails];
        newOrders[index].productCost = e.target.value;
        setLocalOrderDetails(newOrders);
    };

    const calculateItemTotalPrice = (order) => {
        if (order.productCost !== null) {
            return (parseFloat(order.productCost) * parseFloat(order.purchasedQuantity)).toFixed(2);
        } else {
            return (parseFloat(order.productCost) * parseFloat(order.quantity)).toFixed(2);
        }
    };
    const userId = localStorage.getItem("id");

    useEffect(() => {
        if (orderDetails.length > 0) {
            // Initialize purchasedQuantity to quantity if not already set
            const initializedOrderDetails = orderDetails.map(order => ({
                ...order,
                purchasedQuantity: order.purchasedQuantity || order.quantity
            }));
            setOrderDetails(initializedOrderDetails);


        }
    }, [orderDetails.length]);


    const updateOrderProductAndHandleProductUpdateVendor = async (ids, purchasedQuantity, productId, productCost, weightInGrams, formData) => {

        if (purchasedQuantity == null || productCost == null || productCost < 0) {
            setErrors({
                purchasedQuantity: purchasedQuantity == null,
                productCost: productCost == null || productCost < 0,
            });
            toast.error('Please enter valid details.');
            return;
        }

        try {
            // Ensure isPurchased and lock_Flag are set to true
            const updatedFormData = {
                ...formData,
                userId: userId,
                ids: ids,
                purchasedQuantity: purchasedQuantity,
                productId: productId,
                productCost: productCost ?? "",
                weightInGrams: weightInGrams
            };

            // First API call: Update Order Product
            const orderResponse = await api.post(`/UpdateProductAndOrderProduct`, updatedFormData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });

            // Clear the form data
            setFormData({
                id: '',
                purchasedQuantity: null,
                pricePerQty: null,
                weightPerQty: null,
                isPurchased: null,
            });

            // Update the order details in local state
            const updatedOrders = localOrderDetails.map((orderDetail) =>
                orderDetail.id === ids ? { ...orderResponse.data, productCost: updatedFormData.productCost } : orderDetail
            );
            setLocalOrderDetails(updatedOrders);
            setUpdatedOrderId(ids);

            if (selectedVendor) {
                toggleModal(selectedVendor); // Close and reopen the modal to refresh it
                setIsModalOpen(true);
            }
            // Fetch updated order details if a vendor is selected
            if (selectedVendor) {
                const fetchResponse = await api.post(`/OrderDetailsbyVendorId?vendorId=${selectedVendor.id}`,
                    {}, // Pass an empty object for the request body if there is no body
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                setOrderDetails(fetchResponse.data);
                calculateItemTotalPrice(fetchResponse.data);
            }

            fetchVendors();

            // Show success toast
            if (orderResponse) {
                toast.success('Purchased Successfully!');
            }

        } catch (error) {
            console.error('Error updating order product:', error);
            toast.error('Failed to update order product. Please try again later.');
        }

        //window.location.href = "/vendororder";
    };

    const [products, setProducts] = useState([]);



    const updateOrderProductAndHandleProductUpdateVendorList = async () => {
        // Filter products to update based on the condition that both purchasedQuantity and productCost are filled
        const productsToUpdate = orderDetails.filter(product =>
            product.purchasedQuantity !== null && product.productCost !== "" && product.purchasedQuantity !== "" && product.productCost !== null
        );
        console.log(productsToUpdate);

        // Validate the selected products
        const errors = productsToUpdate.map(product => ({
            purchasedQuantity: product.purchasedQuantity == null,
            productCost: product.productCost == null || product.productCost < 0,
        })).filter(error => error.purchasedQuantity || error.productCost);

        if (errors.length > 0) {
            setErrors(errors);
            toast.error('Please enter valid details for the selected products.');
            return;
        }

        try {

            // Prepare the list data for API call
            const updatedFormDataList = productsToUpdate
                .map(product => ({
                    ids: Array.isArray(product.ids) ? product.ids : [product.ids],
                    purchasedQuantity: product.purchasedQuantity ?? 0,
                    productId: product.productId,
                    productCost: product.productCost,
                    weightInGrams: product.weightInGrams ?? 0,
                    userId: userId // Ensure userId is defined
                }));

            // API call: Update Order Products
            const orderResponse = await api.post(`/UpdateProductAndOrderProductList`, updatedFormDataList, {
                headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
            });

            // Reset form data
            setFormData({
                id: '',
                purchasedQuantity: null,
                pricePerQty: null,
                weightPerQty: null,
                isPurchased: null,
            });


            // Update the order details in local state
            const updatedOrders = localOrderDetails.map(orderDetail =>
                updatedFormDataList.find(updatedProduct => updatedProduct.ids.includes(orderDetail.id))
                    ? { ...orderDetail, productCost: updatedFormDataList.find(updatedProduct => updatedProduct.ids.includes(orderDetail.id)).productCost }
                    : orderDetail
            );
            setLocalOrderDetails(updatedOrders);
            setUpdatedOrderId(updatedFormDataList.map(product => product.ids));

            // Refresh modal and vendor-related data if a vendor is selected
            if (selectedVendor) {
                toggleModal(selectedVendor); // Close and reopen the modal to refresh it
                setIsModalOpen(true);

                // Fetch updated order details by vendor
                const fetchResponse = await api.post(`/OrderDetailsbyVendorId?vendorId=${selectedVendor.id}`, {}, {
                    headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                });

                setOrderDetails(fetchResponse.data);
                calculateItemTotalPrice(fetchResponse.data);
            }

            fetchVendors();

            //const { message } = orderResponse.data;
            // Show success toast
            // if (orderResponse) {
            //     toast.success(`${message}`);
            // }
            if (orderResponse) {
                toast.success('Updating All OrderProduct');
            }

        } catch (error) {
            console.error('Error updating order product:', error);
            toast.success('Updating OrderProduct');
        }
    };







    const updateOrderProductAndHandleProductNotAvailable = async (ids) => {
        try {
            // Ensure isPurchased and lock_Flag are set to true
            const updatedFormData = {
                ...formData,
                ids: ids,

            };

            // First API call: Update Order Product
            const orderResponse = await api.post(`/ProductNotAvailable`, updatedFormData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });




            // Update the order details in local state
            const updatedOrders = localOrderDetails.map((orderDetail) =>
                orderDetail.id === ids ? { ...orderResponse.data, productCost: updatedFormData.productCost } : orderDetail
            );
            setLocalOrderDetails(updatedOrders);
            setUpdatedOrderId(ids);

            if (selectedVendor) {
                toggleModal(selectedVendor); // Close and reopen the modal to refresh it
                setIsModalOpen(true);
            }
            // Fetch updated order details if a vendor is selected
            if (selectedVendor) {
                const fetchResponse = await api.post(`/OrderDetailsbyVendorId?vendorId=${selectedVendor.id}`,
                    {}, // Pass an empty object for the request body if there is no body
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                setOrderDetails(fetchResponse.data);
                calculateItemTotalPrice(fetchResponse.data);
            }

            fetchVendors();
            // Show success toast
            if (orderResponse) {
                toast.success('Order Marked as Not Available!');
            }
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error updating order product:', error);
            toast.error('Failed to Order Cancelled!. Please try again later.');
        }

    };



    const clearSortOrder = () => {
        setSearchQuery('');
        setSortOption("");
        setSortOrder(""); // Reset sorting order to default
        Cookies.remove('sortOptions'); // Clear the cookie
        Cookies.remove('searchVendorOrder'); // Clear the cookie
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchVendorOrder');
        if (savedQuery) {
            setSearchQuery(savedQuery);
        }
    }, []);

    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchVendorOrder', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const filteredVendors = vendors
        //.filter(vendor => vendor.date !== null)
        //.filter(vendor => vendor.purchasedItems !== vendor.totalOrderProducts)
        .filter(vendor => {
            const vendorMatch = vendor.vendorName && vendor.vendorName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
            const dasherMatch = vendor.dasherName && vendor.dasherName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
            const phoneMatch = vendor.mobile_Number && vendor.mobile_Number.toString().includes(searchQuery.toString());
            const addressMatch = vendor.address && vendor.address.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
            return phoneMatch || addressMatch || vendorMatch || dasherMatch;
        })
        // Modify the sorting logic in filteredVendors
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.vendorName.localeCompare(b.vendorName);
            } else if (sortOrder === 'desc') {
                return b.vendorName.localeCompare(a.vendorName);
            } else {
                // Handle the default sorting order
                return 0; // No sorting applied (or you can use any other default sorting logic)
            }
        });

    const applyFilter = (orders) => {
        if (filter === 'pending') {
            return orders.filter(orderDetail => orderDetail.is_Purchased === false);
        } else if (filter === 'purchased') {
            return orders.filter(orderDetail => orderDetail.is_Purchased === true);
        } else {
            return orders;
        }
    };



    // Calculate the total number of pages
    // const pageCount = Math.ceil(filteredVendors.length / itemsPerPage);
    // Update current page if it exceeds the number of pages after filtering
    const pageCount = Math.ceil(filteredVendors.length / itemsPerPage);
    if (currentPage > pageCount && pageCount > 0) {
        setCurrentPage(pageCount);
    }

    // Get the vendors for the current page
    const currentVendors = filteredVendors.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    // Pagination calculations
    const indexOfLastVendor = currentPage * itemsPerPage;
    const indexOfFirstVendor = indexOfLastVendor - itemsPerPage;
    // const currentVendors = filteredVendors.slice(indexOfFirstVendor, indexOfLastVendor);

    const role = localStorage.getItem("role");
    const id = localStorage.getItem("id");

    useEffect(() => {
        const savedSortOption = Cookies.get('sortOptions');
        if (savedSortOption) {
            setSortOption(savedSortOption);
            // Initialize sort order based on saved sort option
            handleSortChange({ target: { value: savedSortOption } });
        }
    }, []);

    //   const handleSortChange = (event) => {
    //     const value = event.target.value;
    //     setSortOption(value);
    //     Cookies.set('sortOption', value, { expires: 7 }); // Cookie expires in 7 days

    const handleSortChange = (event) => {
        const value = event.target.value;
        setSortOption(value);
        Cookies.set('sortOptions', value, { expires: 7 }); // Cookie expires in 7 days

        // Call appropriate sorting function based on selected option
        if (value === 'AtoZ') {
            setSortOrder('asc');
        } else if (value === 'ZtoA') {
            setSortOrder('desc');
        }
    };

    const [errors, setErrors] = useState({
        purchasedQuantity: false,
        productCost: false,
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : 'top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }}
            />
            <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    size="medium"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" style={{ fontSize: '20px' }}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        maxWidth: 240,
                        margin: isMobile ? '8px 0' : '4px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.375rem',
                            '&.Mui-focused fieldset': {
                                borderColor: '#3e3e45',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.2rem',
                        },
                    }}
                />

                <FormControl
                    sx={{
                        // width: isMobile ? '100%' : 'fit-content',
                        // margin: isMobile ? '8px 0 0 70%' : '0 0 0 0',
                        width: isMobile ? '100%' : 'auto',
                        maxWidth: 240,
                        margin: isMobile ? '8px 0' : '4px',
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#3e3e45', // Outline color when focused
                            },
                        },
                    }}
                >
                    <InputLabel id="sort-label" style={{ color: '#3e3e45' }}>Sort by</InputLabel>
                    <Select
                        labelId="sort-label"
                        value={sortOption}
                        label="Sort By"
                        onChange={handleSortChange}
                        sx={{
                            width: isMobile ? '100%' : '155px',
                            borderRadius: '0.375rem',
                            padding: '12px 0px',
                        }}
                    >
                        <MenuItem value="">
                            <em>Sort by</em>
                        </MenuItem>
                        <MenuItem value="AtoZ">A to Z</MenuItem>
                        <MenuItem value="ZtoA">Z to A</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip title="Clear">
                    <Button
                        onClick={clearSortOrder}
                        variant="contained"
                        style={{ color: 'white', backgroundColor: '#3e3e45' }}
                        sx={{
                            marginLeft: isMobile ? '0' : 'auto',
                            marginTop: isMobile ? '10px' : '0',
                            marginBottom: isMobile ? '10px' : '0',
                            width: isMobile ? '40%' : 'auto',
                        }}
                    >
                        <LuFilterX style={{ fontSize: '20px' }} />
                    </Button>
                </Tooltip>

            </Box>
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                    {currentVendors
                        .map((vendor, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index} sx={{ minWidth: '350px' }}>
                                <Tooltip title="View Details">
                                    <Card sx={{
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#0000001c',
                                            boxShadow: '6px 6px 15px 1px #646464a1'
                                        },

                                    }
                                    }  >
                                        <CardActionArea onClick={() => toggleModal(vendor)}>

                                            <CardContent style={{ height: '100%' }}>
                                                <MDBox
                                                    mt='10px'
                                                    mb='15px'
                                                    p='10px'
                                                    //variant="gradient"
                                                    variant="contained"
                                                    style={{
                                                        //background: 'linear-gradient(195deg, #42424a, #191919)',
                                                        background: '#3e3e45', // Using background instead of bgColor: 
                                                        borderRadius: "10px",
                                                        boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Example coloredShadow
                                                        width: "100%"

                                                    }}
                                                >
                                                    <Typography align="center" style={{ color: 'white' }} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxHeight: '2em' }}>
                                                        {vendor.vendorName}
                                                    </Typography>
                                                </MDBox>

                                                {/* <Typography gutterBottom variant="h5" component="div"  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxHeight: '2em' }}>
                                                {vendor.createdAt} 
                                            </Typography> */}
                                                <Typography align="center" variant="body1" style={{ fontSize: 'large', fontWeight: '500' }} component="div">
                                                    <span style={{ color: '#e80e0e' }}>Pending: {vendor.pendingItems}</span>
                                                </Typography>
                                                {/* <Typography variant="body1" component="div" sx={{ marginTop: '10px' }} >
                                                    Purchased:  {vendor.purchasedItems} &nbsp; &nbsp;|&nbsp;&nbsp; Pending: {vendor.pendingItems}
                                                </Typography> */}
                                                <Typography align="center" style={{ fontWeight: '500' }} variant="body1" component="div" >
                                                    Total Order Products : {vendor.totalOrderProducts}
                                                </Typography>
                                                {localStorage.getItem("role") !== "Dasher" ? (
                                                    <Typography align="center" variant="body1" component="div" >
                                                        <span style={{ fontWeight: '500' }}>Dasher: {vendor.dasherName}</span>
                                                    </Typography>
                                                ) : null}
                                            </CardContent>

                                        </CardActionArea>
                                    </Card>
                                </Tooltip>
                            </Grid>
                        ))}
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                {pageCount > 1 && (
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        sx={{
                            '& .MuiPaginationItem-root.Mui-selected': {
                                color: '#fff',
                                background: 'linear-gradient(195deg, #42424a, #191919)',
                            },
                        }}
                    />
                )}
            </Box>
            {isModalOpen && (
                <Modal open={isModalOpen} onClose={toggleModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isMobile ? '90%' : 1600,
                            maxWidth: '100%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            borderRadius: 4,
                            p: 2,
                            maxHeight: isMobile ? '80vh' : '90vh',
                            overflowY: 'auto',
                            scrollbarWidth: 'none'

                        }}
                    >
                        <IconButton
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                            style={{ color: 'black', fontWeight: '500' }}
                            onClick={toggleModal}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <Typography variant="body1" color='black' style={{ margin: '10px', fontWeight: '400', fontSize: '25px' }} component="div" >
                                Vendor Order Details
                                {isModalOpen ? (
                                    <Typography>
                                        Vendor Name: {selectedVendor.vendorName}
                                    </Typography>
                                ) : null}
                            </Typography>
                            <Typography style={{ marginLeft: 'auto', marginTop: '40px' }}>
                                <Button
                                    variant='contained'
                                    style={{ color: 'white', backgroundColor: 'rgb(62, 62, 69)', textTransform: 'none', fontWeight: '400',minWidth:'116px', top: isMobile ? '75px' : 0, }}
                                    onClick={updateOrderProductAndHandleProductUpdateVendorList}
                                >
                                    Update All
                                </Button>
                            </Typography>
                        </div>

                        {isMobile ? (
                            <Swiper spaceBetween={10} slidesPerView={1}>
                                {localOrderDetails
                                    .filter(order => order.lock_Flag !== true)
                                    .map((order, index) => (
                                        <SwiperSlide key={index}>
                                            <Box
                                                sx={{
                                                    padding: 2,
                                                    border: '1px solid #ddd',
                                                    borderRadius: '10px',
                                                    mb: 2,
                                                    bgcolor: '#f9f9f9' // Light background for better contrast
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ mb: 1 }}>Item No: {index + 1}</Typography>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Product:</strong> {order.productName}
                                                </Typography>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Qty:</strong> {order.quantity}
                                                </Typography>

                                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <Typography sx={{ mr: 1 }}><strong>Purchased Qty:</strong></Typography>
                                                    <TextField
                                                        type="number"
                                                        value={order.purchasedQuantity || ""}
                                                        sx={{ width: '80px', margin: 0 }}
                                                        inputProps={{ min: 0 }}
                                                        onChange={(e) => handleQuantityChange(e, index)}
                                                        required
                                                    />
                                                </Box>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Weight In Grams:</strong> {order.weightInGrams}
                                                </Typography>

                                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <Typography sx={{ mr: 1 }}><strong>Price Per Qty:</strong></Typography>
                                                    <TextField
                                                        type="number"
                                                        value={order.productCost || ""}
                                                        sx={{ width: '80px', margin: 0 }}
                                                        inputProps={{ min: 0, pattern: "[0-9]*" }}
                                                        onChange={(e) => handlePriceChange(e, index)}
                                                        required
                                                    />
                                                </Box>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Total Weight:</strong> {order.purchasedQuantity * order.weightInGrams} gm
                                                </Typography>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Total Price:</strong> ₹{order.productCost * order.purchasedQuantity}
                                                </Typography>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Status:</strong> {order.is_Purchased ? "Purchased" : "Pending"}
                                                </Typography>

                                                <Typography sx={{ mb: 1 }}>
                                                    <strong>Action:</strong>
                                                    <Tooltip title="Update Order Product">
                                                        <IconButton
                                                            style={{ color: 'black' }}
                                                            onClick={() => updateOrderProductAndHandleProductUpdateVendor(order.ids, order.purchasedQuantity, order.productId, order.productCost, order.weightInGrams)}
                                                            disabled={order.productCost === null || order.quantity === null}
                                                        >
                                                            <IoMdCheckboxOutline />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Cancel / Not Available">
                                                        <IconButton
                                                            style={{ color: 'black' }}
                                                            onClick={() => updateOrderProductAndHandleProductNotAvailable(order.ids)}
                                                            disabled={order.productCost === null || order.quantity === null}
                                                        >
                                                            <FcCancel />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        ) : (
                            <>
                                <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto', scrollbarWidth: 'none' }}>
                                    <Table stickyHeader>
                                        <TableBody>
                                            <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Qty</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Purchased Qty</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Weight In Grams</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Price Per Qty</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Total Weight</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Total Price</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Status</TableCell>
                                                <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Action</TableCell>
                                            </TableRow>
                                            {(localOrderDetails).filter(order => order.lock_Flag !== true).map((order, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ fontWeight: '400' }}>{index + 1}.</TableCell>
                                                    <TableCell style={{ fontWeight: '400' }}>{order.productName}</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: '400' }}>{order.quantity}</TableCell>
                                                    <TableCell align="center"  >
                                                        <TextField
                                                            type="number"
                                                            value={order.purchasedQuantity || ""}
                                                            style={{ width: '80px', margin: '0px', padding: '0', boxSizing: 'border-box' }}
                                                            onChange={(e) => handleQuantityChange(e, index)}
                                                            inputProps={{ min: 0 }} // This sets a minimum value for the input
                                                            required
                                                            InputProps={{
                                                                style: { fontWeight: '400' }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: '400' }} >{order.weightInGrams}</TableCell>
                                                    <TableCell align="center" >
                                                        <TextField
                                                            type="number"
                                                            style={{ width: '80px', margin: '0px', padding: '0', boxSizing: 'border-box' }}
                                                            onChange={(e) => handlePriceChange(e, index)}
                                                            inputProps={{ min: 0, pattern: "[0-9]*" }}
                                                            value={order.productCost || ''}
                                                            required
                                                            InputProps={{
                                                                style: { fontWeight: '400' }
                                                            }}

                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: '400' }} align="center">{order.purchasedQuantity * order.weightInGrams}<span style={{ fontSize: 'medium' }}>gm</span></TableCell>
                                                    <TableCell style={{ fontWeight: '400' }} align="center">₹{order.productCost * order.purchasedQuantity}</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: '400' }}>
                                                        <TableCell
                                                            sx={{
                                                                borderRadius: '4px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '4px 8px',
                                                                fontSize: '12px',
                                                                color: order.is_Purchased !== true ? '#ff0000' : '#008000',
                                                                backgroundColor: order.is_Purchased !== true ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 128, 0, 0.2)',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: '4px',
                                                                    height: '4px',
                                                                    borderRadius: '50%',
                                                                    marginRight: '4px',
                                                                    backgroundColor: order.is_Purchased !== true ? '#ff0000' : '#008000',
                                                                }}
                                                            ></span>
                                                            {order.is_Purchased !== true ? "Pending" : "Purchased"}
                                                        </TableCell>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            padding: '20px 0',
                                                            marginRight: '5px'
                                                        }}
                                                    >
                                                        <Tooltip title="Update Order Product">
                                                            <IconButton

                                                                style={{ color: 'black' }}
                                                                onClick={() => updateOrderProductAndHandleProductUpdateVendor(order.ids, order.purchasedQuantity, order.productId, order.productCost, order.weightInGrams)}
                                                                disabled={order.productCost === null || order.quantity === null}
                                                            >
                                                                <IoMdCheckboxOutline />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Cancel / Not Available">
                                                            <IconButton

                                                                style={{ color: 'white' }}
                                                                onClick={() => updateOrderProductAndHandleProductNotAvailable(order.ids)}
                                                                disabled={order.productCost === null || order.quantity === null}
                                                            >
                                                                <FcCancel />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                    </Box>
                </Modal>
            )
            }
            {/* <Footer dark /> */}
        </DashboardLayout >

    );
};

export default VendorOrder;
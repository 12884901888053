import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    Switch,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    FormControlLabel,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    InputLabel,
    Box,
    useMediaQuery,
    Pagination,
    Paper,
    InputAdornment,
} from '@mui/material';
import { Edit as EditIcon, CheckCircleOutline as ActivateIcon, Block as DeactivateIcon, Margin } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import api from '../../service/api';
import MDBox from "../../components/MDBox"
import { useTheme } from '@mui/material/styles';
import { RiLockPasswordLine } from "react-icons/ri";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { BiEdit } from 'react-icons/bi';
import { LuFilterX } from "react-icons/lu";
import Footer from "layouts/authentication/components/Footer";
import Cookies from 'js-cookie';

const User = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roleId: '',
        password: '',
        isActive: true,
        fulfillment_Center_ID: '',
    });

    const [errors, setErrors] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [centers, setCenters] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [operationFlag, setOperationFlag] = useState(0);
    const [editUserId, setEditUserId] = useState(null);
    const [showActiveUsers, setShowActiveUsers] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openPassModal, setOpenPassModal] = useState(false);
    const [formMode, setFormMode] = useState('add');
    const [openVendorModal, setOpenVendorModal] = useState(false);
    const [vendorDetails, setVendorDetails] = useState(null);
    const [userToDeactivate, setUserToDeactivate] = useState(null);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchRole, setSearchRole] = useState('');
    const [hideTextField, setHideTextField] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Fetch Users
    const fetchUsers = useCallback(async () => {
        try {
            const response = await api.get('/GetUsers', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, []);

    // Fetch Roles
    const fetchRoles = useCallback(async () => {
        try {
            const response = await api.get("/GetRole", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, []);

    // Fetch Centers
    const fetchCenters = useCallback(async () => {
        try {
            const response = await api.get("/CenterList", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            setCenters(response.data);
        } catch (error) {
            console.error('Error fetching centers:', error);
        }
    }, []);

    // Call all fetch functions on component mount
    useEffect(() => {
        fetchUsers();
        fetchRoles();
        fetchCenters();
    }, [fetchUsers, fetchRoles, fetchCenters]);

    // Fetch roles when roles change (if needed)
    // useEffect(() => {
    //     fetchUsers();
    // }, [fetchUsers]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = "First Name is required";
        if (!formData.lastName) newErrors.lastName = "Last Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
        if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
        else if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = "Phone Number must be exactly 10 digits";
        // if (!formData.password) newErrors.password = "Password is required";
        // else if (formData.password.length < 6) newErrors.password = "Password must be at least 6 characters long";
        if (!formData.roleId) newErrors.roleId = "Role selection is required";
        if (!formData.fulfillment_Center_ID) newErrors.fulfillment_Center_ID = "Center selection is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handlePassChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const endpoint = formMode === 'edit' ? '/UpdateUser' : '/Registration';
            const response = await api.post(endpoint, formData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            console.log(formMode === 'edit' ? "User updated successfully:" : "User registered successfully:", response.data);
            handleCloseModal();
            resetForm();
            fetchUsers();
            toast.success(formMode === 'add' ? "User Registered  successfully:" : "User Updated successfully:");
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const [passwordData, setPasswordData] = useState({
        Password: '',
    });

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handlePassSubmit = async (e) => {
        e.preventDefault();
        try {
            const endpoint = '/UpdateUser'; // Ensure this is the correct endpoint for password updates
            const response = await api.post(endpoint,
                {
                    id: editUserId,    // Use the editUserId from the state
                    password: formData.password
                },
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            console.log("Password changed successfully:", response.data);
            handleClosePassModal(); // Ensure this function is correctly defined
            toast.success("Password changed successfully");
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error changing password");
        }
    };


    const handleEditUser = (userId) => {
        const userToEdit = users.find(user => user.id === userId);
        if (userToEdit) {
            const canEdit = (currentUserRole === 'Owner' && (userToEdit.id === userId || userToEdit.roleName !== 'Owner' || userToEdit.roleName === 'Admin' || userToEdit.roleName === 'Dasher')) ||
                (currentUserRole === 'Admin' && (userToEdit.roleName !== 'Owner' || userToEdit.roleName !== 'Admin' || userToEdit.roleName === 'Dasher'));

            if (canEdit) {
                setFormData(userToEdit);
                setEditUserId(userId);
                setOperationFlag(1);
                setFormMode('edit');
                handleOpenModal();
            } else {
                console.error("You do not have permission to edit this user");
                toast.error("You do not have permission to edit this user.");
            }
        } else {
            console.error("User not found for editing");
            toast.error("User not found.");
        }
    };

    const handleChangePassword = (userId) => {
        const userToChangePassword = users.find(user => user.id === userId);

        if (userToChangePassword) {
            const canChangePassword = (currentUserRole === 'Owner' && (userToChangePassword.id === userId || userToChangePassword.roleName !== 'Owner' || userToChangePassword.roleName === 'Admin' || userToChangePassword.roleName === 'Dasher')) ||
                (currentUserRole === 'Admin' && (userToChangePassword.roleName !== 'Owner' || userToChangePassword.roleName !== 'Admin' || userToChangePassword.roleName === 'Dasher'));

            if (canChangePassword) {
                // Set the user data for the password change
                setFormData({ password: '' }); // Initialize with an empty password field
                setEditUserId(userId); // Set the user ID for further processing
                setOperationFlag(1);
                setFormMode('changePassword'); // Set form mode to password change

            } else {
                console.error("You do not have permission to change this user's password");
                toast.error("You do not have permission to change this user's password.");
            }
        } else {
            console.error("User not found for password change");
            toast.error("User not found.");
        }
    };

    const handleToggleActive = async (userId) => {
        const userToToggle = users.find(user => user.id === userId);
        if (userToToggle) {
            const userRole = roles.find(role => role.id === userToToggle.roleId)?.roleName;
            const canToggleActive = (currentUserRole === 'Owner' && (userRole === 'Admin' || userRole === 'Dasher')) ||
                (currentUserRole === 'Admin' && userRole === 'Dasher');

            if (!canToggleActive) {
                toast.error('You do not have permission to toggle the active status of this user.');
                return;
            }

            if (userToToggle.isActive) {
                try {
                    const response = await api.post(`/GetVendorsByUserId?id=${userId}`, {}, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
                    const userVendor = response.data.find(vendor => vendor.userId === userId);
                    setVendors(response.data);
                    if (userVendor) {
                        setVendorDetails([userVendor]);
                        setUserToDeactivate(userToToggle);
                        setOpenVendorModal(true);
                    } else {
                        await updateUserStatus(userToToggle);
                    }
                } catch (error) {
                    console.error('Error fetching vendor details:', error);
                    toast.error('An error occurred while fetching vendor details.');
                }
            } else {
                await updateUserStatus(userToToggle);
            }
        } else {
            console.error("User not found for toggling active status");
            toast.error("User not found.");
        }
    };

    const updateUserStatus = async (user) => {
        const updatedUser = { ...user, isActive: !user.isActive };
        try {
            const response = await api.post('/UpdateUser', updatedUser, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            const responseMessage = response.data;
            if (responseMessage === "Updated User") toast.success('User status updated successfully!');
            else if (responseMessage === "Failed To Update") toast.error('Failed to update user status.');
            else if (responseMessage === "User is Allocated to vendor Please Remove Allocation Before Disabling") toast.warning('User is allocated to vendor. Please remove allocation before disabling.');
            else toast.error('User is allocated to vendor. Please remove allocation before disabling.');
            fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
            toast.error('An error occurred while updating user status.');
        }
    };

    const handleDeallocate = async () => {
        try {
            const response = await api.post(`/DeallocateByUserId?userId=${userToDeactivate.id}`, {}, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            console.log('Deallocate API response:', response.data);
            toast.success('User deallocated from vendor successfully!');
            setOpenVendorModal(false);
            await updateUserStatus(userToDeactivate);
            const userVendor = response.data.filter(vendor => vendor.userId === userToDeactivate.id);
            setVendors(userVendor);
        } catch (error) {
            console.error('Error deallocating user:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            roleId: '',
            password: '',
            isActive: true,
            fulfillment_Center_ID: '',
        });
        setErrors({});
        setFormMode('add');
        setEditUserId(null);
        setOperationFlag(0);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        //resetForm();
    };

    const handleOpenPassModal = () => {
        setOpenPassModal(true);
        //resetForm();
    };

    const handleCloseModal = () => setOpenModal(false);
    const handleClosePassModal = () => setOpenPassModal(false);

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchQuery');
        if (savedQuery) {
            setSearchQuery(savedQuery);
        }
    }, []);

    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchQuery', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    // const handleSearchTermChange = (event) => {
    //     setSearchQuery(event.target.value);
    // }


    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchRole');
        if (savedQuery) {
            setSearchRole(savedQuery);
        }
    }, []);

    const handleSearchRoleChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchRole(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchRole', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    // const handleSearchRoleChange = (event) => {
    //     setSearchRole(event.target.value);
    // }

    const filterUsers = (usersList) => usersList.filter((user) => {
        //const userName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const firstName = user.firstName && user.firstName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
        const lastName = user.lastName && user.lastName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
        const email = user.email && user.email.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
        const roleName = user.roleName && user.roleName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
        const phoneNumber = user.phoneNumber && user.phoneNumber.toString().includes(searchQuery.toString());
        const centerName = user.centerName && user.centerName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString());
        const roleMatch = searchRole ? user.roleName === searchRole : true;
        return (firstName || lastName || email || roleName || phoneNumber || centerName) && roleMatch;
        // return userName.includes(searchQuery.toLowerCase()) || email.includes(searchQuery.toLowerCase()) || phoneNumber.includes(searchQuery.toString()) || roleName.includes(searchQuery.toLowerCase()) || centerName.includes(searchQuery.toString()) && roleMatch;
    })

    const [loggedInUserId, setLoggedInUserId] = useState(null);

    const userName = (vendors.length > 0 ? vendors[0].userName : 'No User');

    const currentUserRole = localStorage.getItem('role');
    const filteredUsers = users.filter(user => showActiveUsers ? user.isActive : !user.isActive);
    const filteredUsersWithSearch = filterUsers(filteredUsers);
    //const pageCount = Math.ceil(filteredUsersWithSearch.length / itemsPerPage);
    // Update current page if it exceeds the number of pages after filtering
    const pageCount = Math.ceil(filteredUsersWithSearch.length / itemsPerPage);
    if (currentPage > pageCount && pageCount > 0) {
        setCurrentPage(pageCount);
    }
    const currentPageUsers = filteredUsersWithSearch.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    const clearSortOrder = () => {
        setSearchQuery('');
        Cookies.remove('searchQuery'); // Clear the cookie
        Cookies.remove('searchRole'); // Clear the cookie
        setSearchRole('');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : 'top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }}
            />
            <div className="p-3">
                {/* <MDBox
                    mb={3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <Typography variant="h5" style={{ color: 'white' }} component="h2">
                        User Management
                    </Typography>
                </MDBox> */}
                <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        size="medium"

                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth={isMobile}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ fontSize: '20px' }}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            width: isMobile ? '100%' : 'auto',
                            maxWidth: 240,
                            margin: isMobile ? '8px 0' : '4px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.375rem',
                                '&.Mui-focused fieldset': {
                                    borderColor: '#3e3e45',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '1.2rem',
                            },
                        }}
                    />
                    <FormControl variant="outlined"
                        sx={{
                            width: isMobile ? '100%' : 'auto',
                            maxWidth: 240,
                            margin: isMobile ? '8px 0' : '4px',
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#3e3e45', // Outline color when focused
                                },
                            },
                        }} style={{ width: isMobile ? '100%' : 'auto' }}>
                        <InputLabel id="filter-vendor-label"></InputLabel>
                        <Select
                            id="filterVendor"
                            value={searchRole}
                            onChange={handleSearchRoleChange}
                            displayEmpty
                            // style={{ padding: '10px 0px', borderRadius: '20px', width: isMobile ? '100%' : 'auto' }}
                            sx={{
                                width: isMobile ? '100%' : '155px',
                                maxWidth: '240px',
                                borderRadius: '0.375rem',
                                padding: '12px 0px',
                                marginBottom: isMobile ? '10px' : '0',

                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        color: '#3e3e45',
                                        maxHeight: 200,
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>All Roles</em>
                            </MenuItem>
                            {roles
                                .filter(role => role.roleName !== 'Owner')
                                .map((role) => (
                                    <MenuItem key={role.id} value={role.roleName}>
                                        {role.roleName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <Button
                        variant="contained"
                        style={{ color: 'white', backgroundColor: '#3e3e45', fontWeight: '400', textTransform: 'none', marginLeft: '5px' }}
                        onClick={() => { resetForm(); handleOpenModal('add'); }} // Pass 'add' mode
                    >
                        Add User
                    </Button>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showActiveUsers}
                                onChange={(e) => setShowActiveUsers(e.target.checked)}
                                name="showActiveUsers"
                                color="primary"
                            />
                        }
                        label={showActiveUsers ? " Active Users" : " Inactive Users"}
                        style={{ marginLeft: isMobile ? '0' : 'auto' }}
                        onClick={(e) => e.stopPropagation()}
                    />
                    <Tooltip title="Clear">
                        <Button
                            onClick={clearSortOrder}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3e3e45' }}
                            sx={{
                                marginLeft: isMobile ? '0' : 'auto',
                                marginTop: isMobile ? '10px' : '0',
                                marginBottom: isMobile ? '10px' : '0',
                                width: isMobile ? '40%' : 'auto',
                            }}
                        >
                            <LuFilterX style={{ fontSize: '20px' }} />
                        </Button>
                    </Tooltip>


                </Box>


                {isMobile ? (
                    currentPageUsers.map(user => (
                        <Card sx={{

                            backgroundColor: 'white',
                            border: '0px solid black',
                            margin: '15px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                        }} key={user.id}>

                            <CardContent>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>First Name:</strong> {user.firstName}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Last Name:</strong> {user.lastName}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Email:</strong> {user.email}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Phone Number:</strong> {user.phoneNumber}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Role:</strong> {user.roleName}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Center:</strong> {user.centerName}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body1">
                                        <strong>Status:</strong> {user.isActive ? 'Active' : 'Inactive'}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1">
                                        <strong>Action:</strong>
                                    </Typography>
                                    <Box>
                                        {currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId && (
                                            <Tooltip title="Edit User">
                                                <IconButton style={{ color: '#13a531' }} onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }}>
                                                    <BiEdit />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {!(currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId) && (
                                            (currentUserRole === 'Owner' || (currentUserRole !== 'Admin' || user.roleName !== 'Owner')) && (
                                                <Tooltip title="Edit User">
                                                    <IconButton style={{ color: '#13a531' }} onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }}>
                                                        <BiEdit />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        )}
                                        {((currentUserRole === 'Owner' && (user.roleName === 'Admin' || user.roleName === 'Dasher')) ||
                                            (currentUserRole === 'Admin' && user.roleName === 'Dasher')) && (
                                                <Tooltip title={user.isActive ? "Deactivate User" : "Activate User"}>
                                                    <IconButton
                                                        style={{ color: user.isActive ? 'red' : 'green' }}
                                                        onClick={() => handleToggleActive(user.id)}
                                                    >
                                                        {user.isActive ? <FaEyeSlash /> : <FaEye />}
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        <Tooltip title="Change Password">
                                            <IconButton style={{ color: '#c4c41e' }} onClick={() => { handleOpenPassModal('edit'); }}>
                                                <RiLockPasswordLine />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>

                                </Box>
                            </CardContent>
                        </Card>
                    )))
                    : (
                        <>
                            <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 700, overflow: 'auto', scrollbarWidth: 'none' }}>
                                <Table stickyHeader>
                                    <TableBody>
                                        <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>First Name</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Last Name</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}> Email</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Phone Number</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}> Role</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Center</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}> Status</TableCell>
                                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Actions</TableCell>
                                        </TableRow>
                                        {currentPageUsers.map((user, index) => (
                                            <TableRow key={user.id}>
                                                <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.firstName}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.lastName}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.email}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.phoneNumber}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.roleName}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.centerName}</TableCell>
                                                <TableCell style={{ fontWeight: '500' }}>{user.isActive ? 'Active' : 'Inactive'}</TableCell>
                                                <TableCell>
                                                    {currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId ? (
                                                        <Tooltip title="Edit User">
                                                            <IconButton
                                                                style={{ color: '#13a531' }}
                                                                onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }} // Pass 'edit' mode
                                                            >
                                                                <BiEdit />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}

                                                    {(currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId) ? (
                                                        // Hide edit button for other Owners
                                                        null
                                                    ) : (
                                                        currentUserRole === 'Owner' || (currentUserRole !== 'Admin' || user.roleName !== 'Owner') ? (
                                                            <Tooltip title="Edit User">
                                                                <IconButton
                                                                    style={{ color: '#13a531' }}
                                                                    onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }} // Pass 'edit' mode
                                                                >
                                                                    <BiEdit />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null
                                                    )}
                                                    {(currentUserRole === 'Owner' && (user.roleName === 'Admin' || user.roleName === 'Dasher')) ||
                                                        (currentUserRole === 'Admin' && user.roleName === 'Dasher') ? (
                                                        <Tooltip title={user.isActive ? "Deactivate User" : "Activate User"}>
                                                            <IconButton style={{ color: user.isActive ? 'red' : 'green' }} onClick={() => handleToggleActive(user.id)}>
                                                                {user.isActive ? <FaEyeSlash /> : <FaEye />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}
                                                    <Tooltip title="Change Password">
                                                        <IconButton style={{ color: '#c4c41e' }} onClick={() => { handleChangePassword(user.id); handleOpenPassModal('edit'); }}>
                                                            <RiLockPasswordLine />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                {/* <Pagination
                    count={Math.ceil(filteredUsersWithSearch.length / 10)}
                    page={page}
                    onChange={handlePageChange}
                    style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                /> */}
                {/* Pagination */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {pageCount > 1 && (
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handleChangePage}
                            color='primary'
                            sx={{
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    color: '#fff',
                                    background: 'linear-gradient(195deg, #42424a, #191919)',
                                },
                            }}
                        />
                    )}
                </Box>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card
                    style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '600px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
                >
                    <Typography variant="h5" align="center">{formMode === 'add' ? 'Add User' : 'Edit User'}</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={(e) => {
                                // Only allow digits (0-9)
                                const regex = /^[0-9\b]+$/;
                                if (e.target.value === '' || regex.test(e.target.value)) {
                                    handleInputChange(e);
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                            type="tel"
                            InputProps={{
                                inputProps: {
                                    style: { MozAppearance: 'textfield' }  // For Firefox
                                },
                                style: {
                                    WebkitAppearance: 'none',  // For Chrome, Safari, Edge, Opera
                                    MozAppearance: 'textfield', // For Firefox
                                }
                            }}
                        />


                        {(formMode === 'add' &&
                            <TextField
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                variant="outlined"
                                type="password"
                                fullWidth
                                margin="normal"
                                required
                                error={!!errors.password}
                                helperText={errors.password}
                            />
                        )}



                        <FormControl variant="outlined" error={!!errors.fulfillment_Center_ID} style={{ marginBottom: '20px', marginLeft: '-10px', width: '50%' }} required>
                            <Select
                                id="ddlCenter"
                                onChange={handleInputChange}
                                name="fulfillment_Center_ID"
                                value={formData.fulfillment_Center_ID || ''}
                                displayEmpty
                                style={{ margin: '10px', padding: '10px 0px' }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    <>Select Center</>
                                </MenuItem>
                                {centers.map((center) => (
                                    <MenuItem key={center.id} value={center.id}>
                                        {center.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" error={!!errors.roleId} style={{ marginBottom: '20px', marginLeft: '-10px', width: '50%' }} required>
                            <Select
                                id="ddlRole"
                                onChange={handleInputChange}
                                name="roleId"
                                value={formData.roleId || ''}
                                displayEmpty
                                style={{ margin: '10px', padding: '10px 0px' }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    <>Select Role</>
                                </MenuItem>
                                {roles
                                    .filter(role => role.roleName !== 'Owner') // Filter out 'Owner' role
                                    .map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.roleName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.isActive}
                                    onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                                    name="isActive"
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', color: 'white', width: 'fit-content', backgroundColor: '#3e3e45', textTransform: 'none' }}
                            >
                                {formMode === 'edit' ? 'Update User' : 'Add User'}
                            </Button></div>
                    </form>
                </Card>
            </Modal>

            {/* <Modal
                open={openPassModal}
                onClose={handleClosePassModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card
                    style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '600px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
                >
                    <Typography variant="h5" align="center">Change Password</Typography>

                    <form onSubmit={handlePassSubmit}>
                        <TextField
                            label="Password"
                            name="password"
                            value={passwordData.password}
                            onChange={handlePasswordChange}
                            variant="outlined"
                            type="password"
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', color: 'white', width: 'fit-content', backgroundColor: '#3e3e45', textTransform: 'none' }}
                            >
                                Update Password
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal> */}
            <Modal
                open={openPassModal}
                onClose={handleClosePassModal}
                aria-labelledby="password-modal-title"
                aria-describedby="password-modal-description"
            >
                <Card
                    style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '400px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
                >
                    <Typography variant="h5" align="center">Change Password</Typography>
                    <form onSubmit={handlePassSubmit}>
                        <TextField
                            label="Password"
                            name="password"  // Corrected name attribute
                            value={formData.password}
                            onChange={handlePassChange}
                            variant="outlined"
                            type="password"
                            fullWidth
                            margin="normal"
                            required
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', color: 'white', width: 'fit-content', backgroundColor: '#3e3e45', textTransform: 'none' }}
                            >
                                Update Password
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal>

            <Dialog
                open={openVendorModal}
                onClose={() => setOpenVendorModal(false)}
                aria-labelledby="vendor-modal-title"
                aria-describedby="vendor-modal-description"
            >
                <DialogTitle
                    id="vendor-modal-title"
                    style={{
                        textAlign: 'center',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: '#333'
                    }}
                >
                    Vendor Allocation Details

                </DialogTitle>
                <span
                    style={{
                        textAlign: 'center',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        marginTop: '5px',
                        textTransform: 'uppercase'
                    }}
                >
                    {userName}
                </span>
                <DialogContent
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}
                >
                    {vendors.map((vendor, index) => (
                        <div
                            key={index}
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px dashed black',
                                backgroundColor: '#eee'
                            }}
                        >
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: '1.2rem',
                                    color: 'black'
                                }}
                            >
                                {vendor.vendorName}
                            </p>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenVendorModal(false)}
                        color='secondary'
                        variant="contained"
                        style={{ color: 'white', marginBottom: '15px' }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeallocate}
                        variant="contained"
                        style={{ color: 'white', backgroundColor: 'red', marginBottom: '15px', marginLeft: 'auto' }}>
                        Deallocate
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Footer dark /> */}
        </DashboardLayout>
    );
};

export default User;



// import React, { useState, useEffect, useCallback } from 'react';
// import {
//     Container,
//     Grid,
//     Card,
//     CardContent,
//     Typography,
//     TextField,
//     Button,
//     FormControl,
//     Select,
//     MenuItem,
//     Switch,
//     TableContainer,
//     Table,
//     TableBody,
//     TableRow,
//     TableCell,
//     FormControlLabel,
//     Modal,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     IconButton,
//     InputLabel,
//     Box,
//     useMediaQuery,
//     Pagination,
//     Paper,
//     InputAdornment,
// } from '@mui/material';
// import { Edit as EditIcon, CheckCircleOutline as ActivateIcon, Block as DeactivateIcon, Margin } from '@mui/icons-material';
// import { toast, ToastContainer } from 'react-toastify';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import api from '../../service/api';
// import MDBox from "../../components/MDBox"
// import { useTheme } from '@mui/material/styles';
// import { FaEyeSlash, FaEye } from "react-icons/fa";
// import SearchIcon from '@mui/icons-material/Search';
// import Tooltip from '@mui/material/Tooltip';
// import { BiEdit } from 'react-icons/bi';
// import { LuFilterX } from "react-icons/lu";
// import Footer from "layouts/authentication/components/Footer";

// const User = () => {
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         phoneNumber: '',
//         roleId: '',
//         //password: '',
//         isActive: true,
//         fulfillment_Center_ID: '',
//     });

//     const [errors, setErrors] = useState({});
//     const [searchQuery, setSearchQuery] = useState("");
//     const [users, setUsers] = useState([]);
//     const [roles, setRoles] = useState([]);
//     const [centers, setCenters] = useState([]);
//     const [vendors, setVendors] = useState([]);
//     const [operationFlag, setOperationFlag] = useState(0);
//     const [editUserId, setEditUserId] = useState(null);
//     const [showActiveUsers, setShowActiveUsers] = useState(true);
//     const [openModal, setOpenModal] = useState(false);
//     const [formMode, setFormMode] = useState('add');
//     const [openVendorModal, setOpenVendorModal] = useState(false);
//     const [vendorDetails, setVendorDetails] = useState(null);
//     const [userToDeactivate, setUserToDeactivate] = useState(null);
//     const [page, setPage] = useState(1);
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 10;
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchRole, setSearchRole] = useState('');

//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     // Fetch Users
//     const fetchUsers = useCallback(async () => {
//         try {
//             const response = await api.get('/GetUsers', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             setUsers(response.data);
//         } catch (error) {
//             console.error('Error fetching users:', error);
//         }
//     }, []);

//     // Fetch Roles
//     const fetchRoles = useCallback(async () => {
//         try {
//             const response = await api.get("/GetRole", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             setRoles(response.data);
//         } catch (error) {
//             console.error('Error fetching roles:', error);
//         }
//     }, []);

//     // Fetch Centers
//     const fetchCenters = useCallback(async () => {
//         try {
//             const response = await api.get("/CenterList", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             setCenters(response.data);
//         } catch (error) {
//             console.error('Error fetching centers:', error);
//         }
//     }, []);

//     // Call all fetch functions on component mount
//     useEffect(() => {
//         fetchUsers();
//         fetchRoles();
//         fetchCenters();
//     }, [fetchUsers, fetchRoles, fetchCenters]);

//     // Fetch roles when roles change (if needed)
//     // useEffect(() => {
//     //     fetchUsers();
//     // }, [fetchUsers]);

//     const validateForm = () => {
//         const newErrors = {};
//         if (!formData.firstName) newErrors.firstName = "First Name is required";
//         if (!formData.lastName) newErrors.lastName = "Last Name is required";
//         if (!formData.email) newErrors.email = "Email is required";
//         else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
//         if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
//         else if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = "Phone Number must be exactly 10 digits";
//         // if (!formData.password) newErrors.password = "Password is required";
//         // else if (formData.password.length < 6) newErrors.password = "Password must be at least 6 characters long";
//         if (!formData.roleId) newErrors.roleId = "Role selection is required";
//         if (!formData.fulfillment_Center_ID) newErrors.fulfillment_Center_ID = "Center selection is required";

//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0;
//     };

//     const handleInputChange = (event) => {
//         const { name, value } = event.target;
//         setFormData({ ...formData, [name]: value });
//     };
//     const clearSortOrder = () => {
//         setSearchQuery('');
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!validateForm()) return;

//         try {
//             const endpoint = formMode === 'edit' ? '/UpdateUser' : '/Registration';
//             const response = await api.post(endpoint, formData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             console.log(formMode === 'edit' ? "User updated successfully:" : "User registered successfully:", response.data);
//             handleCloseModal();
//             resetForm();
//             fetchUsers();
//             toast.success(formMode === 'add' ? "User Registered  successfully:" : "User Updated successfully:");
//         } catch (error) {
//             console.error("Error:", error);
//         }
//     };

//     const handleEditUser = (userId) => {
//         const userToEdit = users.find(user => user.id === userId);
//         if (userToEdit) {
//             const canEdit = (currentUserRole === 'Owner' && (userToEdit.id === userId || userToEdit.roleName !== 'Owner' || userToEdit.roleName === 'Admin' || userToEdit.roleName === 'Dasher')) ||
//                 (currentUserRole === 'Admin' && (userToEdit.roleName !== 'Owner' || userToEdit.roleName !== 'Admin' || userToEdit.roleName === 'Dasher'));

//             if (canEdit) {
//                 setFormData(userToEdit);
//                 setEditUserId(userId);
//                 setOperationFlag(1);
//                 setFormMode('edit');
//                 handleOpenModal();
//             } else {
//                 console.error("You do not have permission to edit this user");
//                 toast.error("You do not have permission to edit this user.");
//             }
//         } else {
//             console.error("User not found for editing");
//             toast.error("User not found.");
//         }
//     };

//     const handleToggleActive = async (userId) => {
//         const userToToggle = users.find(user => user.id === userId);
//         if (userToToggle) {
//             const userRole = roles.find(role => role.id === userToToggle.roleId)?.roleName;
//             const canToggleActive = (currentUserRole === 'Owner' && (userRole === 'Admin' || userRole === 'Dasher')) ||
//                 (currentUserRole === 'Admin' && userRole === 'Dasher');

//             if (!canToggleActive) {
//                 toast.error('You do not have permission to toggle the active status of this user.');
//                 return;
//             }

//             if (userToToggle.isActive) {
//                 try {
//                     const response = await api.post(`/GetVendorsByUserId?id=${userId}`, {}, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//                     const userVendor = response.data.find(vendor => vendor.userId === userId);
//                     setVendors(response.data);
//                     if (userVendor) {
//                         setVendorDetails([userVendor]);
//                         setUserToDeactivate(userToToggle);
//                         setOpenVendorModal(true);
//                     } else {
//                         await updateUserStatus(userToToggle);
//                     }
//                 } catch (error) {
//                     console.error('Error fetching vendor details:', error);
//                     toast.error('An error occurred while fetching vendor details.');
//                 }
//             } else {
//                 await updateUserStatus(userToToggle);
//             }
//         } else {
//             console.error("User not found for toggling active status");
//             toast.error("User not found.");
//         }
//     };

//     const updateUserStatus = async (user) => {
//         const updatedUser = { ...user, isActive: !user.isActive };
//         try {
//             const response = await api.post('/UpdateUser', updatedUser, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             const responseMessage = response.data;
//             if (responseMessage === "Updated User") toast.success('User status updated successfully!');
//             else if (responseMessage === "Failed To Update") toast.error('Failed to update user status.');
//             else if (responseMessage === "User is Allocated to vendor Please Remove Allocation Before Disabling") toast.warning('User is allocated to vendor. Please remove allocation before disabling.');
//             else toast.error('User is allocated to vendor. Please remove allocation before disabling.');
//             fetchUsers();
//         } catch (error) {
//             console.error('Error updating user:', error);
//             toast.error('An error occurred while updating user status.');
//         }
//     };

//     const handleDeallocate = async () => {
//         try {
//             const response = await api.post(`/DeallocateByUserId?userId=${userToDeactivate.id}`, {}, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
//             console.log('Deallocate API response:', response.data);
//             toast.success('User deallocated from vendor successfully!');
//             setOpenVendorModal(false);
//             await updateUserStatus(userToDeactivate);
//             const userVendor = response.data.filter(vendor => vendor.userId === userToDeactivate.id);
//             setVendors(userVendor);
//         } catch (error) {
//             console.error('Error deallocating user:', error);
//         }
//     };

//     const resetForm = () => {
//         setFormData({
//             firstName: '',
//             lastName: '',
//             email: '',
//             phoneNumber: '',
//             roleId: '',
//             password: '',
//             isActive: true,
//             fulfillment_Center_ID: '',
//         });
//         setErrors({});
//         setFormMode('add');
//         setEditUserId(null);
//         setOperationFlag(0);
//     };

//     const handleOpenModal = () => {
//         setOpenModal(true);
//         //resetForm();
//     };

//     const handleCloseModal = () => setOpenModal(false);

//     const handleSearchTermChange = (event) => setSearchQuery(event.target.value);
//     const handleSearchRoleChange = (event) => setSearchRole(event.target.value);

//     const filterUsers = (usersList) => usersList.filter((user) => {
//         const userName = `${user.firstName} ${user.lastName}`.toLowerCase();
//         const email = user.email && user.email.toLowerCase();
//         const roleName = user.roleName && user.roleName.toLowerCase();
//         const phoneNumber = user.phoneNumber && user.phoneNumber.toString();
//         const centerName = user.centerName && user.centerName.toString();
//         const roleMatch = searchRole ? user.roleName === searchRole : true;
//         return userName.includes(searchQuery.toLowerCase()) || email.includes(searchQuery.toLowerCase()) || phoneNumber.includes(searchQuery.toString()) || roleName.includes(searchQuery.toLowerCase()) || centerName.includes(searchQuery.toString()) && roleMatch;
//     });


//     const [loggedInUserId, setLoggedInUserId] = useState(null);

//     const userName = (vendors.length > 0 ? vendors[0].userName : 'No User');

//     const currentUserRole = localStorage.getItem('role');
//     const filteredUsers = users.filter(user => showActiveUsers ? user.isActive : !user.isActive);
//     const filteredUsersWithSearch = filterUsers(filteredUsers);
//     const pageCount = Math.ceil(filteredUsersWithSearch.length / itemsPerPage);
//     const currentPageUsers = filteredUsersWithSearch.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     const handleChangePage = (event, newPage) => setCurrentPage(newPage);

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <ToastContainer
//                 style={{
//                     position: isMobile ? 'top-right' : 'top-right',
//                     width: isMobile ? '80%' : '25%',
//                     fontSize: isMobile ? '12px' : '15px'
//                 }}
//             />
//             <div className="p-3">
//                 {/* <MDBox
//                     mb={3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="info"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                 >
//                     <Typography variant="h5" style={{ color: 'white' }} component="h2">
//                         User Management
//                     </Typography>
//                 </MDBox> */}
//                 <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
//                     <TextField
//                         placeholder="Search by Name"
//                         variant="outlined"
//                         size="medium"

//                         value={searchQuery}
//                         onChange={handleSearchTermChange}
//                         fullWidth={isMobile}
//                         InputProps={{
//                             startAdornment: (
//                                 <InputAdornment position="start">
//                                     <SearchIcon />
//                                 </InputAdornment>
//                             ),
//                         }}
//                         sx={{
//                             width: isMobile ? '100%' : 'auto',
//                             maxWidth: 240,
//                             margin: isMobile ? '8px 0' : '4px',
//                             '& .MuiOutlinedInput-root': {
//                                 borderRadius: '20px',
//                                 '&.Mui-focused fieldset': {
//                                     borderColor: '#3e3e45',
//                                 },
//                             },
//                             '& .MuiInputLabel-root': {
//                                 fontSize: '1.2rem',
//                             },
//                         }}
//                     />
//                     <FormControl variant="outlined"
//                         sx={{
//                             width: isMobile ? '100%' : 'auto',
//                             maxWidth: 240,
//                             margin: isMobile ? '8px 0' : '4px',
//                             '& .MuiOutlinedInput-root': {
//                                 '&.Mui-focused fieldset': {
//                                     borderColor: '#3e3e45', // Outline color when focused
//                                 },
//                             },
//                         }} style={{ width: isMobile ? '100%' : 'auto' }}>
//                         <InputLabel id="filter-vendor-label"></InputLabel>
//                         <Select
//                             id="filterVendor"
//                             value={searchRole}
//                             onChange={handleSearchRoleChange}
//                             displayEmpty
//                             // style={{ padding: '10px 0px', borderRadius: '20px', width: isMobile ? '100%' : 'auto' }}
//                             sx={{
//                                 width: isMobile ? '100%' : '155px',
//                                 maxWidth: '240px',
//                                 borderRadius: '20px',
//                                 padding: '10px 0px',
//                                 marginBottom: isMobile ? '10px' : '0',

//                             }}
//                             MenuProps={{
//                                 PaperProps: {
//                                     style: {
//                                         color: '#3e3e45',
//                                         maxHeight: 200,
//                                     },
//                                 },
//                             }}
//                         >
//                             <MenuItem value="">
//                                 <em>All Roles</em>
//                             </MenuItem>
//                             {roles
//                                 .filter(role => role.roleName !== 'Owner')
//                                 .map((role) => (
//                                     <MenuItem key={role.id} value={role.roleName}>
//                                         {role.roleName}
//                                     </MenuItem>
//                                 ))}
//                         </Select>
//                     </FormControl>

//                     <Button
//                         variant="contained"
//                         style={{ color: 'white', backgroundColor: '#3e3e45', textTransform: 'none' }}
//                         onClick={() => { resetForm(); handleOpenModal('add'); }} // Pass 'add' mode
//                     >
//                         Add User
//                     </Button>
//                     <FormControlLabel
//                         control={
//                             <Switch
//                                 checked={showActiveUsers}
//                                 onChange={(e) => setShowActiveUsers(e.target.checked)}
//                                 name="showActiveUsers"
//                                 color="primary"
//                             />
//                         }
//                         label={showActiveUsers ? " Active Users" : " Inactive Users"}
//                         style={{ marginLeft: isMobile ? '0' : 'auto' }}
//                         onClick={(e) => e.stopPropagation()}
//                     />
//                     <Tooltip title="Clear Sort">
//                         <Button
//                             onClick={clearSortOrder}
//                             variant='contained'
//                             style={{ color: 'white', backgroundColor: '#3e3e45' }}
//                             sx={{
//                                 marginLeft: isMobile ? '0' : 'auto',
//                                 marginTop: isMobile ? '10px' : '0',
//                                 marginBottom: isMobile ? '10px' : '0',
//                                 width: isMobile ? '40%' : 'auto',
//                             }}
//                         >
//                             <LuFilterX />
//                         </Button>
//                     </Tooltip>

//                 </Box>

//                 <TableContainer component={Paper} sx={{ mt: 2 }}>

//                     <Table aria-label="users-table">
//                         <TableBody sx={{ backgroundColor: '#F6F6F2' }}>
//                             {isMobile ? (
//                                 currentPageUsers.map(user => (
//                                     <Card sx={{

//                                         backgroundColor: 'white',
//                                         border: '0px solid black',
//                                         margin: '15px',
//                                         borderRadius: '10px',
//                                         boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//                                         transition: 'transform 0.2s ease-in-out',
//                                         '&:hover': {
//                                             transform: 'scale(1.02)',
//                                             boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
//                                         },
//                                     }} key={user.id}>

//                                         <CardContent>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>First Name:</strong> {user.firstName}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Last Name:</strong> {user.lastName}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Email:</strong> {user.email}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Phone Number:</strong> {user.phoneNumber}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Role:</strong> {user.roleName}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Center:</strong> {user.centerName}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" mb={2}>
//                                                 <Typography variant="body1">
//                                                     <strong>Status:</strong> {user.isActive ? 'Active' : 'Inactive'}
//                                                 </Typography>
//                                             </Box>
//                                             <Box display="flex" justifyContent="space-between" alignItems="center">
//                                                 <Typography variant="body1">
//                                                     <strong>Action:</strong>
//                                                 </Typography>
//                                                 <Box>
//                                                     {currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId && (
//                                                         <Tooltip title="Edit User">
//                                                             <IconButton style={{ color: '#13a531' }} onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }}>
//                                                                 <BiEdit />
//                                                             </IconButton>
//                                                         </Tooltip>
//                                                     )}
//                                                     {!(currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId) && (
//                                                         (currentUserRole === 'Owner' || (currentUserRole !== 'Admin' || user.roleName !== 'Owner')) && (
//                                                             <Tooltip title="Edit User">
//                                                                 <IconButton style={{ color: '#13a531' }} onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }}>
//                                                                     <BiEdit />
//                                                                 </IconButton>
//                                                             </Tooltip>
//                                                         )
//                                                     )}
//                                                     {((currentUserRole === 'Owner' && (user.roleName === 'Admin' || user.roleName === 'Dasher')) ||
//                                                         (currentUserRole === 'Admin' && user.roleName === 'Dasher')) && (
//                                                             <Tooltip title={user.isActive ? "Deactivate User" : "Activate User"}>
//                                                                 <IconButton
//                                                                     style={{ color: user.isActive ? 'red' : 'green' }}
//                                                                     onClick={() => handleToggleActive(user.id)}
//                                                                 >
//                                                                     {user.isActive ? <FaEyeSlash /> : <FaEye />}
//                                                                 </IconButton>
//                                                             </Tooltip>
//                                                         )}

//                                                 </Box>

//                                             </Box>
//                                         </CardContent>
//                                     </Card>
//                                 )))
//                                 : (<>
//                                     <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>

//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>First Name</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>Last Name</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}> Email</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>Phone Number</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}> Role</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>Center</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}> Status</TableCell>
//                                         <TableCell style={{ fontWeight: '500', color: 'white' }}>Actions</TableCell>
//                                     </TableRow>

//                                     {currentPageUsers.map((user, index) => (
//                                         <TableRow key={user.id}>
//                                             <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.firstName}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.lastName}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.email}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.phoneNumber}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.roleName}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.centerName}</TableCell>
//                                             <TableCell style={{ fontWeight: '500' }}>{user.isActive ? 'Active' : 'Inactive'}</TableCell>
//                                             <TableCell>
//                                                 {currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId ? (
//                                                     <Tooltip title="Edit User">
//                                                         <IconButton
//                                                             style={{ color: '#13a531' }}
//                                                             onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }} // Pass 'edit' mode
//                                                         >
//                                                             <BiEdit />
//                                                         </IconButton>
//                                                     </Tooltip>
//                                                 ) : null}

//                                                 {(currentUserRole === 'Owner' && user.roleName === 'Owner' && user.id === loggedInUserId) ? (
//                                                     // Hide edit button for other Owners
//                                                     null
//                                                 ) : (
//                                                     currentUserRole === 'Owner' || (currentUserRole !== 'Admin' || user.roleName !== 'Owner') ? (
//                                                         <Tooltip title="Edit User">
//                                                             <IconButton
//                                                                 style={{ color: '#13a531' }}
//                                                                 onClick={() => { handleEditUser(user.id); handleOpenModal('edit'); }} // Pass 'edit' mode
//                                                             >
//                                                                 <BiEdit />
//                                                             </IconButton>
//                                                         </Tooltip>
//                                                     ) : null
//                                                 )}
//                                                 {(currentUserRole === 'Owner' && (user.roleName === 'Admin' || user.roleName === 'Dasher')) ||
//                                                     (currentUserRole === 'Admin' && user.roleName === 'Dasher') ? (
//                                                     <Tooltip title={user.isActive ? "Deactivate User" : "Activate User"}>
//                                                         <IconButton style={{ color: user.isActive ? 'red' : 'green' }} onClick={() => handleToggleActive(user.id)}>
//                                                             {user.isActive ? <FaEyeSlash /> : <FaEye />}
//                                                         </IconButton>
//                                                     </Tooltip>
//                                                 ) : null}
//                                             </TableCell>

//                                         </TableRow>
//                                     ))}
//                                 </>)}

//                         </TableBody>
//                     </Table>

//                 </TableContainer>
//                 {/* <Pagination
//                     count={Math.ceil(filteredUsersWithSearch.length / 10)}
//                     page={page}
//                     onChange={handlePageChange}
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                 /> */}
//                 {/* Pagination */}
//                 <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//                     {pageCount > 1 && (
//                         <Pagination
//                             count={pageCount}
//                             page={currentPage}
//                             onChange={handleChangePage}
//                             color='primary'
//                             sx={{
//                                 '& .MuiPaginationItem-root.Mui-selected': {
//                                     color: '#fff',
//                                     background: 'linear-gradient(195deg, #42424a, #191919)',
//                                 },
//                             }}
//                         />
//                     )}
//                 </Box>
//             </div>
//             <Modal
//                 open={openModal}
//                 onClose={handleCloseModal}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Card
//                     style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '600px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
//                 >
//                     <Typography variant="h5" align="center">{formMode === 'add' ? 'Add User' : 'Edit User'}</Typography>
//                     <form onSubmit={handleSubmit}>
//                         <TextField
//                             label="First Name"
//                             name="firstName"
//                             value={formData.firstName}
//                             onChange={handleInputChange}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             required
//                             error={!!errors.firstName}
//                             helperText={errors.firstName}
//                         />
//                         <TextField
//                             label="Last Name"
//                             name="lastName"
//                             value={formData.lastName}
//                             onChange={handleInputChange}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             required
//                             error={!!errors.lastName}
//                             helperText={errors.lastName}
//                         />
//                         <TextField
//                             label="Email"
//                             name="email"
//                             value={formData.email}
//                             onChange={handleInputChange}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             required
//                             error={!!errors.email}
//                             helperText={errors.email}
//                         />
//                         <TextField
//                             label="Phone Number"
//                             name="phoneNumber"
//                             value={formData.phoneNumber}
//                             onChange={(e) => {
//                                 // Only allow digits (0-9)
//                                 const regex = /^[0-9\b]+$/;
//                                 if (e.target.value === '' || regex.test(e.target.value)) {
//                                     handleInputChange(e);
//                                 }
//                             }}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             required
//                             error={!!errors.phoneNumber}
//                             helperText={errors.phoneNumber}
//                             type="tel"
//                             InputProps={{
//                                 inputProps: {
//                                     style: { MozAppearance: 'textfield' }  // For Firefox
//                                 },
//                                 style: {
//                                     WebkitAppearance: 'none',  // For Chrome, Safari, Edge, Opera
//                                     MozAppearance: 'textfield', // For Firefox
//                                 }
//                             }}
//                         />


//                         {(formMode === 'add' &&
//                             <TextField
//                                 label="Password"
//                                 name="password"
//                                 value={formData.password}
//                                 onChange={handleInputChange}
//                                 variant="outlined"
//                                 type="password"
//                                 fullWidth
//                                 margin="normal"
//                                 required
//                                 error={!!errors.password}
//                                 helperText={errors.password}
//                             />
//                         )}



//                         <FormControl variant="outlined" error={!!errors.fulfillment_Center_ID} style={{ marginBottom: '20px', marginLeft: '-10px', width: '50%' }} required>
//                             <Select
//                                 id="ddlCenter"
//                                 onChange={handleInputChange}
//                                 name="fulfillment_Center_ID"
//                                 value={formData.fulfillment_Center_ID || ''}
//                                 displayEmpty
//                                 style={{ margin: '10px', padding: '10px 0px' }}
//                                 MenuProps={{
//                                     PaperProps: {
//                                         style: {
//                                             maxHeight: 200,
//                                         },
//                                     },
//                                 }}
//                             >
//                                 <MenuItem value="" disabled>
//                                     <>Select Center</>
//                                 </MenuItem>
//                                 {centers.map((center) => (
//                                     <MenuItem key={center.id} value={center.id}>
//                                         {center.name}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                         <FormControl variant="outlined" error={!!errors.roleId} style={{ marginBottom: '20px', marginLeft: '-10px', width: '50%' }} required>
//                             <Select
//                                 id="ddlRole"
//                                 onChange={handleInputChange}
//                                 name="roleId"
//                                 value={formData.roleId || ''}
//                                 displayEmpty
//                                 style={{ margin: '10px', padding: '10px 0px' }}
//                                 MenuProps={{
//                                     PaperProps: {
//                                         style: {
//                                             maxHeight: 200,
//                                         },
//                                     },
//                                 }}
//                             >
//                                 <MenuItem value="" disabled>
//                                     <>Select Role</>
//                                 </MenuItem>
//                                 {roles
//                                     .filter(role => role.roleName !== 'Owner') // Filter out 'Owner' role
//                                     .map((role) => (
//                                         <MenuItem key={role.id} value={role.id}>
//                                             {role.roleName}
//                                         </MenuItem>
//                                     ))}
//                             </Select>
//                         </FormControl>
//                         <FormControlLabel
//                             control={
//                                 <Switch
//                                     checked={formData.isActive}
//                                     onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
//                                     name="isActive"
//                                     color="primary"
//                                 />
//                             }
//                             label="Active"
//                         />
//                         <div style={{ display: 'flex', justifyContent: 'center' }}>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 fullWidth
//                                 style={{ marginTop: '10px', color: 'white', width: 'fit-content', backgroundColor: '#3e3e45', textTransform: 'none' }}
//                             >
//                                 {formMode === 'edit' ? 'Update User' : 'Add User'}
//                             </Button></div>
//                     </form>
//                 </Card>
//             </Modal>
//             <Dialog
//                 open={openVendorModal}
//                 onClose={() => setOpenVendorModal(false)}
//                 aria-labelledby="vendor-modal-title"
//                 aria-describedby="vendor-modal-description"
//             >
//                 <DialogTitle
//                     id="vendor-modal-title"
//                     style={{
//                         textAlign: 'center',
//                         fontSize: '1.5rem',
//                         fontWeight: 'bold',
//                         color: '#333'
//                     }}
//                 >
//                     Vendor Allocation Details

//                 </DialogTitle>
//                 <span
//                     style={{
//                         textAlign: 'center',
//                         fontSize: '1rem',
//                         fontWeight: 'bold',
//                         marginTop: '5px',
//                         textTransform: 'uppercase'
//                     }}
//                 >
//                     {userName}
//                 </span>
//                 <DialogContent
//                     style={{
//                         padding: '20px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         gap: '10px'
//                     }}
//                 >
//                     {vendors.map((vendor, index) => (
//                         <div
//                             key={index}
//                             style={{
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: '1px dashed black',
//                                 backgroundColor: '#eee'
//                             }}
//                         >
//                             <p
//                                 style={{
//                                     margin: 0,
//                                     fontSize: '1.2rem',
//                                     color: 'black'
//                                 }}
//                             >
//                                 {vendor.vendorName}
//                             </p>
//                         </div>
//                     ))}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setOpenVendorModal(false)}
//                         color='secondary'
//                         variant="contained"
//                         style={{ color: 'white', marginBottom: '15px' }}>
//                         Cancel
//                     </Button>
//                     <Button
//                         onClick={handleDeallocate}
//                         variant="contained"
//                         style={{ color: 'white', backgroundColor: 'red', marginBottom: '15px', marginLeft: 'auto' }}>
//                         Deallocate
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             {/* <Footer dark /> */}
//         </DashboardLayout>
//     );
// };

// export default User;
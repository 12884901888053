import React, { useState, useEffect, useRef } from 'react';
import moment from "moment-timezone";
import { TableCell, Table, TableContainer, TableBody, Card, CardContent, TableRow, Button, TextField, FormControl, Select, MenuItem, InputLabel, Paper, Modal, Box, Typography, Grid, Pagination, colors, IconButton } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { BiPlusCircle, BiEdit } from 'react-icons/bi';
import api from '../../service/api';
import MDBox from "../../components/MDBox"
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { LuFilterX } from "react-icons/lu";
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from 'js-cookie';

const Product = () => {
    const [formData, setFormData] = useState({
        productName: '',
        productCost: 0,
        priceInRupees: 0,
        vendorId: '',
    });

    const [errors, setErrors] = useState({
        productName: '',
        productCost: '',
        priceInRupees: '',
        vendorId: '',
    });

    const [vendors, setVendors] = useState([]);
    const [products, setProducts] = useState([]);
    const [operationFlag, setOperationFlag] = useState(0); // 0 for add, 1 for edit
    const [editProductId, setEditProductId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const isFirstRun = useRef(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            const [vendorsResponse, productsResponse] = await Promise.all([
                api.get('/GetVendor', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }),
                api.get('/GetProduct', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
            ]);
            setVendors(vendorsResponse.data);
            setProducts(productsResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSignIn = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        const newErrors = { ...errors };

        if (!formData.productName) {
            newErrors.productName = 'Product Name is required';
            formIsValid = false;
        } else {
            newErrors.productName = '';
        }

        if (!formData.productCost) {
            newErrors.productCost = 'Selling Price is required';
            formIsValid = false;
        } else {
            newErrors.productCost = '';
        }

        if (!formData.priceInRupees) {
            newErrors.priceInRupees = 'Cost of Goods Sold is required';
            formIsValid = false;
        } else {
            newErrors.priceInRupees = '';
        }

        // if (!formData.priceInRupees) {
        //     newErrors.priceInRupees = 'Price In Rupees is required';
        //     formIsValid = false;
        // } else {
        //     newErrors.priceInRupees = '';
        // }

        if (!formData.vendorId) {
            newErrors.vendorId = 'Vendor Id is required';
            formIsValid = false;
        } else {
            newErrors.vendorId = '';
        }

        setErrors(newErrors);

        if (formIsValid) {
            try {
                let response;
                if (operationFlag === 0) {
                    response = await api.post(`/AddEditProduct?flag=${operationFlag}`, formData, {
                        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                    });
                    console.log('Product added successfully:', response.data);
                    toast.success('Product added successfully!');
                } else if (operationFlag === 1) {
                    response = await api.post(`/AddEditProduct?flag=${operationFlag}`, formData, {
                        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                    });
                    console.log('Product updated successfully:', response.data);
                    toast.success('Product updated successfully!');
                }
                setOperationFlag(0);
                setIsModalOpen(false);
                fetchData(); // Consider a better way to refresh the data
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred. Please try again.');
            }
        }

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditProduct = (productId) => {
        const productToEdit = products.find(product => product.id === productId);

        if (productToEdit) {
            setFormData({
                id: productToEdit.id,
                productName: productToEdit.productName,
                productCost: productToEdit.productCost,
                priceInRupees: productToEdit.priceInRupees,
                vendorId: productToEdit.vendorId,
            });
            setEditProductId(productId);
            setOperationFlag(1);
            setIsModalOpen(true); // Open modal for editing
        } else {
            console.error("Product not found for editing");
        }
    };

    const handleOpenAddModal = () => {
        setFormData({
            productName: '',
            priceInRupees: 0,
            vendorId: '',
        });
        setOperationFlag(0); // Set operation flag for adding
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredProducts = products.filter(product =>
        product.productName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
        product.vendorName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
        product.mobile_Number && product.mobile_Number.toString().includes(searchQuery.toString()) ||
        product.address && product.address.toLowerCase().toString().includes(searchQuery.toLowerCase().toString())
    );

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    // Calculate the total number of pages
    const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

    // Get the vendors for the current page
    const slicedProducts = filteredProducts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    // Pagination calculations
    const indexOfLastVendor = currentPage * itemsPerPage;
    const indexOfFirstVendor = indexOfLastVendor - itemsPerPage;

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchProduct');
        if (savedQuery) {
            setSearchQuery(savedQuery);
        }
    }, []);

    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchProduct', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    const clearSortOrder = () => {
        setSearchQuery('');
        Cookies.remove('searchProduct'); // Clear the cookie
    };

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredProducts.length - page * rowsPerPage);
    // const slicedProducts = filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <DashboardLayout>
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : 'top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }}
            />
            <DashboardNavbar />
            <div className="p-3 ">
                <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>

                    <TextField
                        placeholder="Search"
                        type="text"
                        value={searchQuery}
                        //onChange={(e) => setSearchQuery(e.target.value)}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="medium"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ fontSize: '20px' }}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            width: isMobile ? '100%' : 'auto',
                            maxWidth: 240,
                            margin: isMobile ? '8px 0' : '4px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.375rem',
                                '&.Mui-focused fieldset': {
                                    borderColor: '#3e3e45',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '1.2rem',
                            },
                        }}
                    />

                    <Button style={{ color: 'white', backgroundColor: '#3e3e45', fontWeight: '400', textTransform: 'none', marginLeft: '5px' }} onClick={handleOpenAddModal}>
                        Add Product
                    </Button>

                    <Tooltip title="Clear">
                        <Button
                            onClick={clearSortOrder}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3e3e45' }}
                            sx={{
                                marginLeft: isMobile ? '0' : 'auto',
                                marginTop: isMobile ? '10px' : '0',
                                marginBottom: isMobile ? '10px' : '0',
                                width: isMobile ? '40%' : 'auto',
                            }}
                        >
                            <LuFilterX style={{ fontSize: '20px' }} />
                        </Button>
                    </Tooltip>


                </Box>


                {isMobile ? (
                    slicedProducts.map(product => (

                        <Card sx={{

                            backgroundColor: 'white',
                            border: '0px solid black',
                            margin: '15px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                        }} key={product.id}>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <strong style={{ marginRight: '16px' }}>Name:</strong>
                                    <span>{product.productName}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong>Selling Price(₹):</strong>
                                    <span>{product.productCost * product.conversionRate}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong>COGS(₹):</strong>
                                    <span>{product.priceInRupees}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong style={{ marginRight: '16px' }}>Vendor:</strong>
                                    <span>{product.vendorName}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong>Created:</strong>
                                    <span>{moment(product.createdAt).format('MMM DD, YYYY')}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong>Updated:</strong>
                                    <span>{product.updatedAt ? moment(product.updatedAt).format('MMM DD, YYYY') : '-'}</span>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                    <strong>Action:</strong>
                                    <IconButton style={{ color: '#13a531' }} onClick={() => handleEditProduct(product.id)}>
                                        <BiEdit />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <>
                        <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 700, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <Table stickyHeader>
                                <TableBody>
                                    <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                                        <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>

                                        <TableCell style={{ fontWeight: '500', color: 'white' }}>Product Name</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Selling Price(₹)</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>COGS(₹)</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Vendor</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Created At</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Updated At</TableCell>
                                        <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
                                    </TableRow>

                                    {slicedProducts.map((product, index) => (
                                        <TableRow key={product.id}>
                                            <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                                            <TableCell style={{ fontWeight: '500' }}>{product.productName}</TableCell>
                                            <TableCell align='center' style={{ fontWeight: '500' }}>{product.productCost * product.conversionRate}</TableCell>
                                            <TableCell align='center' style={{ fontWeight: '500' }}> {product.priceInRupees}</TableCell>
                                            <TableCell align='center' style={{ fontWeight: '500' }}>{product.vendorName}</TableCell>
                                            <TableCell align='center' style={{ fontWeight: '500' }}>{moment(product.createdAt).format('MMM DD, YYYY')}</TableCell>
                                            <TableCell align='center' style={{ fontWeight: '500' }}>{product.updatedAt ? moment(product.updatedAt).format('MMM DD, YYYY') : '-'}</TableCell>
                                            <Tooltip title="Edit Product">
                                            <TableCell align='center'>
                                                <IconButton style={{ color: '#13a531' }} onClick={() => handleEditProduct(product.id)}>
                                                    <BiEdit />
                                                </IconButton>
                                            </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                    ))}
                                    {/* {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={7} />
                                            </TableRow>
                                        )} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                {/* Pagination */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                    {pageCount > 1 && (
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handleChangePage}
                            color='primary'
                            sx={{
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    color: '#fff',
                                    background: 'linear-gradient(195deg, #42424a, #191919)',
                                },
                            }}
                        />
                    )}
                </Box>
            </div>

            {/* Modal for Add/Edit Product */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '600px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
                >
                    <Typography id="modal-title" color="black" variant="h6" component="h4" align="center" gutterBottom>
                        {operationFlag === 0 ? 'Add Product' : 'Edit Product'}
                    </Typography>
                    <form onSubmit={handleSignIn}>
                        <TextField
                            label="Product Name"
                            type="text"
                            name="productName"
                            value={formData.productName}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            required

                            style={{ marginBottom: '15px' }}
                        />
                        <TextField
                            label="Selling Price ($)"
                            type="number"
                            name="productCost"
                            value={formData.productCost}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                            InputProps={{
                                style: {
                                    // Remove spinner in WebKit browsers
                                    WebkitAppearance: 'none',
                                    // Remove spinner in Firefox
                                    MozAppearance: 'textfield',
                                },
                                inputProps: {
                                    // Optional: prevents negative values
                                    min: 0,
                                },
                            }}
                        />
                        <TextField
                            label="Cost of Goods Sold (₹)"
                            type="number"
                            name="priceInRupees"
                            value={formData.priceInRupees}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                            InputProps={{
                                style: {
                                    // Remove spinner in WebKit browsers
                                    WebkitAppearance: 'none',
                                    // Remove spinner in Firefox
                                    MozAppearance: 'textfield',
                                },
                                inputProps: {
                                    // Optional: prevents negative values
                                    min: 0,
                                },
                            }}
                        />
                        {/* <TextField
                label="Price In Rupees"
                type="number"
                name="priceInRupees"
                value={formData.priceInRupees}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                error={!!errors.priceInRupees}
                helperText={errors.priceInRupees}
                style={{ marginBottom: '10px' }}
            /> */}
                        {operationFlag === 0 && (
                            <div className="form-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                <FormControl fullWidth variant="outlined" error={!!errors.vendorId} style={{ marginBottom: '20px' }}>
                                    <InputLabel id="ddlVendor-label"></InputLabel>
                                    <Select
                                        labelId="ddlVendor-label"
                                        id="ddlVendor"
                                        onChange={handleInputChange}
                                        name="vendorId"
                                        value={formData.vendorId || ''}
                                        displayEmpty
                                        style={{ padding: '10px 0px', marginBottom: '15px' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Select Vendor</em>
                                        </MenuItem>
                                        {vendors.map((vendor) => (
                                            <MenuItem key={vendor.id} value={vendor.id}>
                                                {vendor.vendorName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {errors.vendorId && <div className="text-danger">{errors.vendorId}</div>}
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', justifyContent: 'center', alignContent: 'center', width: '30%', textTransform: 'none' }} type="submit" >
                                {operationFlag === 0 ? 'Add Product' : 'Update Product'}
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal>

        </DashboardLayout>
    );
};

export default Product;




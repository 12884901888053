
import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { routesAdmin, routesDasher, routesOwner } from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.svg";
import brandDark from "assets/images/logo-ct-dark.png";
import { ToastContainer } from 'react-toastify';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import { AuthProvider } from '../src/AuthContext';
import api from "../src/service/api";
import { Padding, WidthFull, WidthNormal } from "@mui/icons-material";
import { Style } from "paper/dist/paper-core";
import '../node_modules/swiper/swiper-bundle.min.css';
import '../node_modules/swiper/swiper.min.css';

export default function App() {
  const [Authorize, setAuthorize] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const currentUrl = window.location.pathname;
    const fetchAuthorize = async () => {
      try {
          const response = await api.get('/IsAuthorized' , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
          setAuthorize("Authorize fetched: ", response.data); // Log the response data

          
      } catch (error) {
          console.error('Error fetching roles:', error);
          if (error.response.status == '401'  && currentUrl != "/authentication/sign-in") {
            localStorage.removeItem("id");
            localStorage.removeItem("role");
            localStorage.removeItem("token");
            window.location.href = "/authentication/sign-in";
          }
      }
  };
  useEffect(() => {
    fetchAuthorize();
}, []);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const role = localStorage.getItem("role");

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <AuthProvider>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          <ToastContainer />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                // brandName=""
                routes={role === "Admin" ? routesAdmin : routesDasher}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {role === "Admin" ? (
              <>
                {getRoutes(routesAdmin)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            ) : role === "Owner" ? (
              <>
                {getRoutes(routesOwner)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                {getRoutes(routesDasher)}
                <Route path="*" element={<Navigate to="/vendorOrder" />} />
              </>
            )}
          </Routes>

        </ThemeProvider>
      </AuthProvider>
    </CacheProvider>
  ) : (
    <AuthProvider>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              routes={role === "Admin" ? routesAdmin : role === "Owner" ? routesAdmin : routesDasher}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {role === "Admin" ? (
            <>
              {getRoutes(routesAdmin)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          ) : role === "Owner" ? (
            <>
              {getRoutes(routesOwner)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              {getRoutes(routesDasher)}
              <Route path="*" element={<Navigate to="/vendorOrder" />} />
            </>
          )}
        </Routes>

      </ThemeProvider>
    </AuthProvider>

  );

}

App.js




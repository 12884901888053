import React, { useEffect, useState } from 'react';
//import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme, useMediaQuery, Paper, Typography, Box } from '@mui/material';
import Grid from "@mui/material/Grid";
import PropTypes from 'prop-types'; // Import PropTypes

// Dashboard components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import PieChart from "examples/Charts/PieChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

// Data
import api from "../../service/api";


function Dashboard() {
  

  const [orders, setOrders] = useState([]);
  const [shippedorders, setShippedOrders] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust for mobile screens

  // useEffect(() => {
  //   async function fetchOrders() {
  //     try {
  //       const response = await api.get("/OrderByCustomer", {
  //         headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
  //       });
  //       //console.log("Orders fetched: ", response.data);
  //       setOrders(response.data);
  //     } catch (error) {
  //       console.error("Error fetching orders:", error);
  //     }
  //   }

  //   fetchOrders();
  // }, []);
  
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await api.get(`/GetShopifyOrdersByYear?year=${currentYear}`, {
          headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
        });
        //console.log("Orders fetched: ", response.data);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }

    fetchOrders();
  }, []);

  const fetchShippedOrders = async () => {
    try {
      const response = await api.get("/OrderByShipping", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
      setShippedOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    fetchShippedOrders();
  };



  const months = Array.from({ length: currentMonth + 1 }, (_, month) => ({
    month,
    startOfMonth: new Date(currentYear, month, 1),
    endOfMonth: new Date(currentYear, month + 1, 0),
    monthName: new Date(currentYear, month).toLocaleString('default', { month: 'short' })
  }));
  
  const monthNames = [];
  const counts = [];
  
  months.forEach(({ month, monthName }) => {
    const count = orders.filter(item => {
      const orderDate = new Date(item.orderDate);
      // Extract only the year and month from the orderDate
      const orderYear = orderDate.getFullYear();
      const orderMonth = orderDate.getMonth();
      
      // Match only the year and month
      return orderYear === currentYear && orderMonth === month;
    }).length;
  
    monthNames.push(monthName);
    counts.push(count);
  });
  
  const datasets = {
    label: "Orders", 
    data: counts
  };

  const barchartData = {
    monthNames, datasets
  };



  const linechartData = {
      monthNames, datasets
    };

  // // Prepare data for the pie chart
  // const fulfilmentCenterData = orders.reduce(
  //   (acc, order) => {
  //     if (order.fulfilmentCenterId) {
  //       acc.selected += 1;
  //     } else {
  //       acc.notSelected += 1;
  //     }
  //     return acc;
  //   },
  //   { selected: 0, notSelected: 0 }
  // );


  // const piechartname = ['Fulfilment Center Selected','Fulfilment Center Not Selected'];
  
  // const piechartdata = {
  //   label : '',
  //   data : [fulfilmentCenterData.selected,fulfilmentCenterData.notSelected]
  // }

  // const pieData = {
  //   piechartname, piechartdata
  // };

  const LegendItem = ({ color, label }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
      <Box sx={{ width: 16, height: 16, backgroundColor: color, marginRight: 1 }} />
      <Typography variant="body2">{label}</Typography>
    </Box>
  );

  LegendItem.propTypes = {
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* Pie Chart Section */}

        <MDBox mt={2.5}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6} sx={{ height: '400px' }}>
              <MDBox >
                <ReportsBarChart
                  color="info"
                  title="Orders Views"
                  description="Last Year Performance"
                  date="just updated"
                  chart={barchartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox >
                <ReportsLineChart
                  color="dark"
                  title="Order Views"
                  description="Last Year Performance"
                  date="just updated"
                  chart={linechartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2.5}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} lg={6} sx={{ height: '400px' }}>
              <MDBox>
                <PieChart

                  color="info"
                  title="Fulfillment Center"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={pieData}

                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={6} sx={{ height: '500px' }}>
              <MDBox mb={3}>
                <DefaultDoughnutChart

                  color="info"
                  title="Orders Views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={pieData}

                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>


        {/* <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Website Views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Daily Sales"
                  description={<><strong>+15%</strong> increase in today sales.</>}
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Completed Tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer dark /> */}
    </DashboardLayout>
  );
}

export default Dashboard;

// import React, { useEffect, useState, useRef } from 'react';
// import api from '../../../service/api';
// import { useLocation } from 'react-router-dom';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableRow,
//     Typography,
//     Card,
//     CardContent,
//     Grid,
//     Container,
//     Button,
//     TableHead,
//     useMediaQuery,
//     useTheme,
//     TextField,
//     Box,
//     IconButton,
//     Paper,
//     Pagination
// } from '@mui/material';
// import MDBox from "../../../components/MDBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { ToastContainer, toast } from 'react-toastify';
// import Tooltip from '@mui/material/Tooltip';
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { IoMdCheckboxOutline } from "react-icons/io";
// import { InputAdornment } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import Footer from "layouts/authentication/components/Footer";
// import { LuFilterX } from "react-icons/lu";
// import Cookies from 'js-cookie';

// const ShippedProductList = () => {
//     const [responseData, setResponseData] = useState([]);
//     const location = useLocation();
//     const tableRef = useRef(null);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 10;

//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     const getCenterIdFromQuery = () => {
//         const params = new URLSearchParams(location.search);
//         return params.get('centerId');
//     };

//     useEffect(() => {
//         const centerId = getCenterIdFromQuery();

//         const fetchShippedProducts = async () => {
//             try {
//                 const response = await api.post(`/ShippedProduct?centerId=${centerId}&userId=${localStorage.getItem("id")}`, {}, {
//                     headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
//                 });
//                 const responseData = response.data.map(item => item);
//                 setResponseData(responseData);
//             } catch (error) {
//                 console.error('Error fetching shipped products:', error);
//             }
//         };

//         fetchShippedProducts();
//     }, [location]);

//     const handleSaveData = async () => {
//         const centerId = getCenterIdFromQuery();
//         try {
//             var ids = responseData.map(item => item.id)

//             const response = await api.post('/ShippedProductUpdate', {
//                 ids: ids,
//             }, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });

//             if (response.status === 200) {
//                 toast.success('Data saved successfully!', {
//                     onClose: () => window.location.reload() // Refresh the page after the toast closes
//                 });
//             } else {
//                 toast.error('Failed to save data.');
//             }
//         } catch (error) {
//             console.error('Error saving data:', error);
//             toast.error('Error saving data.');
//         }
//     };

//     // const handlePriceChange = (e, index) => {
//     //     const product = [...responseData];
//     //     product[index].pricePerQty = e.target.value;
//     //     setResponseData(product);
//     // };
//     const [shippedOrders, setShippedOrders] = useState(responseData); // Initial data from response

//     const updateShippedOrders = (updatedOrders) => {
//         setShippedOrders(updatedOrders); // Update the state with new orders
//     };

//     // Use the index of the shippedOrder array instead of the original index
// const handlePriceChange = (event, index) => {
//     const newPrice = event.target.value;
//     // Update the price for the correct order in the filtered list
//     const updatedOrders = [...filteredShippedOrders];
//     updatedOrders[index] = { ...updatedOrders[index], pricePerQty: newPrice };
//     // Assuming you have a method to update your state
//     updateShippedOrders(updatedOrders);
// };



//     const updateprice = async (opId, price) => {
//         try {

//             // First API call: Update Order Product
//             const orderResponse = await api.post(`/UpdateShippedProducts?opId=${opId}&price=${price}`,
//                 {}, // Pass an empty object for the request body if there is no body
//                 {
//                     headers: {
//                         "Authorization": `Bearer ${localStorage.getItem("token")}`
//                     }
//                 });

//             // Show success toast if the response is not null and the status is OK (e.g., 200)
//             if (orderResponse && orderResponse.status === 200) {
//                 toast.success('Purchased Successfully!');
//             } else {
//                 toast.error('Failed to update order product. Please try again later.');
//             }
//         } catch (error) {
//             console.error('Error updating order product:', error);
//             toast.error('Failed to update order product. Please try again later.');
//         }
//         //window.location.href = "/vendororder";
//     };




//     const filteredShippedOrders = responseData
//     .filter(order =>
//         order.productName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()))

//         const indexOfFirstVendor  = currentPage * itemsPerPage;
//         const indexOfLastVendor = indexOfFirstVendor - itemsPerPage;
  
//     // Update current page if it exceeds the number of pages after filtering
//     const pageCount = Math.ceil(filteredShippedOrders.length / itemsPerPage);
//     if (currentPage > pageCount && pageCount > 0) {
//         setCurrentPage(pageCount);
//     }

//     const handleChangePage = (event, newPage) => setCurrentPage(newPage);

//     const shippedOrder = filteredShippedOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     useEffect(() => {
//         // Retrieve cookie value on component mount
//         const savedQuery = Cookies.get('searchShippedProduct');
//         if (savedQuery) {
//             setSearchQuery(savedQuery);
//         }
//     }, []);

//     const handleSearchChange = (event) => {
//         const newSearchQuery = event.target.value;
//         setSearchQuery(newSearchQuery);
//         // Set cookie with the new search query
//         Cookies.set('searchShippedProduct', newSearchQuery, { expires: 7 }); // Expires in 7 days
//     };

//     const clearSortOrder = () => {
//         setSearchQuery('');
//         Cookies.remove('searchShippedProduct'); // Clear the cookie
//     };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <ToastContainer
//                 style={{
//                     position: isMobile ? 'top-right' : 'top-right',
//                     width: isMobile ? '80%' : '25%',
//                     fontSize: isMobile ? '12px' : '15px'
//                 }}
//             />
//             <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
//                 <TextField
//                     placeholder="Search"
//                     variant="outlined"
//                     size="medium"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                     InputProps={{
//                         startAdornment: (
//                             <InputAdornment position="start" style={{ fontSize: '20px' }}>
//                                 <SearchIcon />
//                             </InputAdornment>
//                         ),
//                     }}
//                     sx={{
//                         width: isMobile ? '100%' : 'auto',
//                         maxWidth: 240,
//                         margin: isMobile ? '8px 0' : '4px',
//                         '& .MuiOutlinedInput-root': {
//                             borderRadius: '0.375rem',
//                             '&.Mui-focused fieldset': {
//                                 borderColor: '#3e3e45',
//                             },
//                         },
//                         '& .MuiInputLabel-root': {
//                             fontSize: '1.2rem',
//                         },
//                     }}
//                 />
//                 <Tooltip title="Save Data">
//                     <Button
//                         variant='contained'
//                         onClick={handleSaveData}
//                         style={{ color: "white", backgroundColor: '#212644fc', fontWeight: '400', textTransform: 'none', marginLeft: '5px' }}
//                     >
//                         Save Data
//                     </Button>
//                 </Tooltip>
//                 <Tooltip title="Clear">
//                     <Button
//                         onClick={clearSortOrder}
//                         variant="contained"
//                         style={{ color: 'white', backgroundColor: '#3e3e45' }}
//                         sx={{
//                             marginLeft: isMobile ? '0' : 'auto',
//                             marginTop: isMobile ? '10px' : '0',
//                             marginBottom: isMobile ? '10px' : '0',
//                             width: isMobile ? '40%' : 'auto',
//                         }}
//                     >
//                         <LuFilterX style={{ fontSize: '20px' }} />
//                     </Button>
//                 </Tooltip>

//             </Box>

//             {isMobile ? (
//                 shippedOrder.map((product, index) => (
//                     <Card
//                         sx={{
//                             backgroundColor: 'white',
//                             border: '0px solid black',
//                             margin: '14px',
//                             borderRadius: '10px',
//                             boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//                             transition: 'transform 0.2s ease-in-out',
//                             '&:hover': {
//                                 transform: 'scale(1.02)',
//                                 boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
//                             },
//                         }}
//                         key={index}
//                     >
//                         <CardContent >
//                             <Box>
//                                 <strong style={{ justifyContent: 'center' }}>Item:</strong>
//                                 <span>{(currentPage - 1) * itemsPerPage + index + 1}</span>
//                             </Box>

//                             <Box>
//                                 <strong style={{ marginRight: '10px' }}>Name:</strong>
//                                 <span>{product.productName}</span>
//                             </Box>
//                             <Box>
//                                 <strong style={{ marginRight: '10px' }}>Quantity:</strong>
//                                 <span>{product.quantity}</span>
//                             </Box>
//                             <Box>
//                                 <strong style={{ marginRight: '10px' }}>Price Per Qty:</strong>
//                                 <TextField
//                                     type="number"
//                                     value={product.pricePerQty || ''}
//                                     style={{ width: '30%' }}
//                                     inputProps={{ min: 0, pattern: "[0-9]*" }}
//                                     onChange={(e) => handlePriceChange(e, (currentPage - 1) * itemsPerPage + index)}
//                                 />
//                             </Box>
//                             {/* <Box>
//                                         <strong style={{ marginRight: '10px' }}>Actual Purchase Date: </strong>
//                                         <span>{new Date(product.dispatch_Date).toLocaleDateString()}</span>
//                                     </Box> */}
//                             {/* <Box>
//                                 <strong style={{ marginRight: '10px' }}>Purchase Date:</strong>
//                                 <span>{new Date(product.purchaseDate).toLocaleDateString()}</span>
//                             </Box> */}
//                             <Box>
//                                 <strong style={{ marginRight: '10px' }}>Actual Purchase Date: </strong>
//                                 <span>{new Date(product.actualPurchaseDate).toLocaleDateString()}</span>
//                             </Box>
//                             <Box
//                                 sx={{
//                                     display: 'flex',
//                                     flexDirection: 'row',
//                                     justifyContent: 'center',
//                                     width: '100%',

//                                 }}>
//                                 <strong style={{ marginRight: '10px' }}>Action: </strong>
//                                 <Tooltip title="Update Price">
//                                     <IconButton
//                                         style={{ color: 'black', marginLeft: 'auto' }}
//                                         onClick={() => updateprice(product.id, product.pricePerQty)}
//                                     >
//                                         <IoMdCheckboxOutline />
//                                     </IconButton>
//                                 </Tooltip>
//                             </Box>
//                         </CardContent>
//                     </Card>
//                 ))
//             ) : (<>
//                 <TableContainer ref={tableRef} component={Paper} sx={{ mt: 1, maxHeight: 700, overflow: 'auto', scrollbarWidth: 'none' }}>
//                     <Table stickyHeader>
//                         <TableBody>
//                             <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
//                                 <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
//                                 <TableCell style={{ fontWeight: '500', color: 'white' }}>Product Name</TableCell>
//                                 <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Quantity</TableCell>
//                                 <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Price Per Qty</TableCell>
//                                 {/* <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Dispatch Date</TableCell> */}
//                                 {/* <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Purchase Date</TableCell> */}
//                                 <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Actual Purchase Date</TableCell>
//                                 <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
//                             </TableRow>

//                             {shippedOrder.map((product, index) => (
//                                 <TableRow key={index}>
//                                     <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
//                                     <TableCell style={{ fontWeight: '500' }}>{product.productName}</TableCell>
//                                     <TableCell style={{ fontWeight: '500' }} align='center'>{product.quantity}</TableCell>
//                                     <TableCell align='center'
//                                         sx={{
//                                             display: 'flex',
//                                             flexDirection: 'row',
//                                             justifyContent: 'center',
//                                             width: '100%',
//                                             borderBottom: '0px solid rgba(224, 224, 224, 1)',
//                                             padding: '8px 0',
//                                         }}
//                                     >

//                                         <TextField
//                                          key={product.id} // Make sure to use a unique key for each item
//                                             type="number"
//                                             value={product.pricePerQty || ''}
//                                             style={{ width: '50%', margin: '0px' }}
//                                             onChange={(e) => handlePriceChange(e, index)} // `index` should refer to the filtered array
//                                             //onChange={(e) => handlePriceChange(e, (currentPage - 1) * itemsPerPage + index)}
//                                             inputProps={{ min: 0, pattern: "[0-9]*" }}
//                                             InputProps={{
//                                                 style: { fontWeight: '500' }
//                                             }}
//                                         />
//                                     </TableCell>
//                                     {/* <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.disp).toLocaleDateString()}</TableCell> */}
//                                     {/* <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.purchaseDate).toLocaleDateString()}</TableCell> */}
//                                     <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.actualPurchaseDate).toLocaleDateString()}</TableCell>
//                                     <TableCell align='center'
//                                         sx={{
//                                             display: 'flex',
//                                             flexDirection: 'row',
//                                             justifyContent: 'center',
//                                             width: '100%',
//                                         }}
//                                     >
//                                         <Tooltip title="Update Price">
//                                             <IconButton
//                                                 style={{ color: 'black' }}
//                                                 onClick={() => updateprice(product.id, product.pricePerQty)}
//                                             >
//                                                 <IoMdCheckboxOutline />
//                                             </IconButton>
//                                         </Tooltip>
//                                     </TableCell>
//                                 </TableRow>
//                             ))}

//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </>
//             )}

//             <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//             {/* {pageCount > 1 && ( */}
//                     <Pagination
                    
//                         count={pageCount}
//                         page={currentPage}
//                         onChange={handleChangePage}
//                         color='primary'
//                         sx={{
//                             '& .MuiPaginationItem-root.Mui-selected': {
//                                 color: '#fff',
//                                 background: 'linear-gradient(195deg, #42424a, #191919)',
//                             },
//                         }}
//                     />
//                 {/* )} */}
//             </Box>
//             {/* <Footer dark /> */}
//         </DashboardLayout>
//     );
// };

// export default ShippedProductList;


import React, { useEffect, useState, useRef } from 'react';
import api from '../../../service/api';
import { useLocation } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Card,
    CardContent,
    Grid,
    Container,
    Button,
    TableHead,
    useMediaQuery,
    useTheme,
    TextField,
    Box,
    IconButton,
    Paper,
    Pagination
} from '@mui/material';
import MDBox from "../../../components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdCheckboxOutline } from "react-icons/io";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Footer from "layouts/authentication/components/Footer";
import { LuFilterX } from "react-icons/lu";
import Cookies from 'js-cookie';

const ShippedProductList = () => {
    const [responseData, setResponseData] = useState([]);
    const location = useLocation();
    const tableRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getCenterIdFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('centerId');
    };

    useEffect(() => {
        const centerId = getCenterIdFromQuery();

        const fetchShippedProducts = async () => {
            try {
                const response = await api.post(`/ShippedProduct?centerId=${centerId}&userId=${localStorage.getItem("id")}`, {}, {
                    headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` }
                });
                const responseData = response.data.map(item => item);
                setResponseData(responseData);
            } catch (error) {
                console.error('Error fetching shipped products:', error);
            }
        };

        fetchShippedProducts();
    }, [location]);

    const handleSaveData = async () => {
        const centerId = getCenterIdFromQuery();
        try {
            var ids = responseData.map(item => item.id)

            const response = await api.post('/ShippedProductUpdate', {
                ids: ids,
            }, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });

            if (response.status === 200) {
                toast.success('Data saved successfully!', {
                    onClose: () => window.location.reload() // Refresh the page after the toast closes
                });
            } else {
                toast.error('Failed to save data.');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('Error saving data.');
        }
    };

    // const handlePriceChange = (e, index) => {
    //     const product = [...responseData];
    //     product[index].pricePerQty = e.target.value;
    //     setResponseData(product);
    // };
    
    const handlePriceChange = (event, index) => {
        const newPrice = event.target.value;
    
        // Update the price for the correct order in the filtered list
        const updatedOrders = [...filteredShippedOrders];
        updatedOrders[index] = { ...updatedOrders[index], pricePerQty: newPrice };
    
        // Update the original responseData with the modified price
        const newResponseData = responseData.map((order) => {
            const updatedOrder = updatedOrders.find((uOrder) => uOrder.id === order.id);
            return updatedOrder ? updatedOrder : order;
        });
    
        setResponseData(newResponseData);
    };
    



    const updateprice = async (opId, price) => {
        try {

            // First API call: Update Order Product
            const orderResponse = await api.post(`/UpdateShippedProducts?opId=${opId}&price=${price}`,
                {}, // Pass an empty object for the request body if there is no body
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                });

            // Show success toast if the response is not null and the status is OK (e.g., 200)
            if (orderResponse && orderResponse.status === 200) {
                toast.success('Purchased Successfully!');
            } else {
                toast.error('Failed to update order product. Please try again later.');
            }
        } catch (error) {
            console.error('Error updating order product:', error);
            toast.error('Failed to update order product. Please try again later.');
        }
        //window.location.href = "/vendororder";
    };




    const filteredShippedOrders = responseData
    .filter(order =>
        order.productName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()))

        const indexOfFirstVendor  = currentPage * itemsPerPage;
        const indexOfLastVendor = indexOfFirstVendor - itemsPerPage;
  
    // Update current page if it exceeds the number of pages after filtering
    const pageCount = Math.ceil(filteredShippedOrders.length / itemsPerPage);
    if (currentPage > pageCount && pageCount > 0) {
        setCurrentPage(pageCount);
    }

    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    const shippedOrder = filteredShippedOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchShippedProduct');
        if (savedQuery) {
            setSearchQuery(savedQuery);
        }
    }, []);

    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchShippedProduct', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    const clearSortOrder = () => {
        setSearchQuery('');
        Cookies.remove('searchShippedProduct'); // Clear the cookie
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : 'top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }}
            />
            <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    size="medium"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" style={{ fontSize: '20px' }}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        maxWidth: 240,
                        margin: isMobile ? '8px 0' : '4px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.375rem',
                            '&.Mui-focused fieldset': {
                                borderColor: '#3e3e45',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.2rem',
                        },
                    }}
                />
                <Tooltip title="Save Data">
                    <Button
                        variant='contained'
                        onClick={handleSaveData}
                        style={{ fontWeight:'500',color: "black", backgroundColor: '#10BC10', fontWeight: '400', textTransform: 'none', marginLeft: '15px' }}
                    >
                        Save Data
                    </Button>
                </Tooltip>
                <Tooltip title="Clear">
                    <Button
                        onClick={clearSortOrder}
                        variant="contained"
                        style={{ color: 'white', backgroundColor: '#3e3e45' }}
                        sx={{
                            marginLeft: isMobile ? '0' : 'auto',
                            marginTop: isMobile ? '10px' : '0',
                            marginBottom: isMobile ? '10px' : '0',
                            width: isMobile ? '40%' : 'auto',
                        }}
                    >
                        <LuFilterX style={{ fontSize: '20px' }} />
                    </Button>
                </Tooltip>

            </Box>

            {isMobile ? (
                shippedOrder
                .map((product, index) => (
                    <Card
                        sx={{
                            backgroundColor: 'white',
                            border: '0px solid black',
                            margin: '14px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                        key={index}
                    >
                        <CardContent >
                            <Box>
                                <strong style={{ justifyContent: 'center' }}>Item:</strong>
                                <span>{(currentPage - 1) * itemsPerPage + index + 1}</span>
                            </Box>

                            <Box>
                                <strong style={{ marginRight: '10px' }}>Name:</strong>
                                <span>{product.productName}</span>
                            </Box>
                            <Box>
                                <strong style={{ marginRight: '10px' }}>Quantity:</strong>
                                <span>{product.quantity}</span>
                            </Box>
                            <Box>
                                <strong style={{ marginRight: '10px' }}>Price Per Qty:</strong>
                                <TextField
                                    type="number"
                                    value={product.pricePerQty || ''}
                                    style={{ width: '30%' }}
                                    inputProps={{ min: 0, pattern: "[0-9]*" }}
                                    onChange={(e) => handlePriceChange(e, (currentPage - 1) * itemsPerPage + index)}
                                />
                            </Box>
                            {/* <Box>
                                        <strong style={{ marginRight: '10px' }}>Actual Purchase Date: </strong>
                                        <span>{new Date(product.dispatch_Date).toLocaleDateString()}</span>
                                    </Box> */}
                            {/* <Box>
                                <strong style={{ marginRight: '10px' }}>Purchase Date:</strong>
                                <span>{new Date(product.purchaseDate).toLocaleDateString()}</span>
                            </Box> */}
                            <Box>
                                <strong style={{ marginRight: '10px' }}>Actual Purchase Date: </strong>
                                <span>{new Date(product.actualPurchaseDate).toLocaleDateString()}</span>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%',

                                }}>
                                <strong style={{ marginRight: '10px' }}>Action: </strong>
                                <Tooltip title="Update Price">
                                    <IconButton
                                        style={{ color: 'black', marginLeft: 'auto' }}
                                        onClick={() => updateprice(product.id, product.pricePerQty)}
                                    >
                                        <IoMdCheckboxOutline />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </CardContent>
                    </Card>
                ))
            ) : (<>
                <TableContainer ref={tableRef} component={Paper} sx={{ mt: 1, maxHeight: 700, overflow: 'auto', scrollbarWidth: 'none' }}>
                    <Table stickyHeader>
                        <TableBody>
                            <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                                <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                                <TableCell style={{ fontWeight: '500', color: 'white' }}>Product Name</TableCell>
                                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Quantity</TableCell>
                                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Price Per Qty</TableCell>
                                {/* <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Dispatch Date</TableCell> */}
                                {/* <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Purchase Date</TableCell> */}
                                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Actual Purchase Date</TableCell>
                                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
                            </TableRow>

                            {shippedOrder
                            .map((product, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                                    <TableCell style={{ fontWeight: '500' }}>{product.productName}</TableCell>
                                    <TableCell style={{ fontWeight: '500' }} align='center'>{product.quantity}</TableCell>
                                    <TableCell align='center'
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%',
                                            borderBottom: '0px solid rgba(224, 224, 224, 1)',
                                            padding: '8px 0',
                                        }}
                                    >

                                        <TextField
                                            type="number"
                                            value={product.pricePerQty || ''}
                                            style={{ width: '50%', margin: '0px' }}
                                            //onChange={(e) => handlePriceChange(e, index)} // `index` should refer to the filtered array
                                            onChange={(e) => handlePriceChange(e, (currentPage - 1) * itemsPerPage + index)}
                                            inputProps={{ min: 0, pattern: "[0-9]*" }}
                                            InputProps={{
                                                style: { fontWeight: '500' }
                                            }}
                                        />
                                    </TableCell>
                                    {/* <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.disp).toLocaleDateString()}</TableCell> */}
                                    {/* <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.purchaseDate).toLocaleDateString()}</TableCell> */}
                                    <TableCell style={{ fontWeight: '500' }} align='center'>{new Date(product.actualPurchaseDate).toLocaleDateString()}</TableCell>
                                    <TableCell align='center'
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Tooltip title="Update Price">
                                            <IconButton
                                                style={{ color: 'black' }}
                                                onClick={() => updateprice(product.id, product.pricePerQty)}
                                            >
                                                <IoMdCheckboxOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {/* {pageCount > 1 && ( */}
                    <Pagination
                    
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color='primary'
                        sx={{
                            '& .MuiPaginationItem-root.Mui-selected': {
                                color: '#fff',
                                background: 'linear-gradient(195deg, #42424a, #191919)',
                            },
                        }}
                    />
                {/* )} */}
            </Box>
            {/* <Footer dark /> */}
        </DashboardLayout>
    );
};

export default ShippedProductList;

import { useState, useEffect, useMemo } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import { AppBar, Toolbar, IconButton, useMediaQuery, useTheme, Typography } from '@mui/material';
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { AiFillHome } from 'react-icons/ai';


import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { Margin, Style } from "@mui/icons-material";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  //const route = useLocation().pathname.split("/").slice(1);
  //const route = useMemo(() => location.pathname.split("/").slice(1), [location.pathname]);
  const location = useLocation();
  const route = location.pathname.split("/").slice(1); // Split and remove the leading empty string
  
  const [userName, setUserName] = useState('');

  const currentPath = location.pathname;
  var currentPage = currentPath.substring(currentPath.lastIndexOf("/") + 1);
  currentPage = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenProfile = (event) => setOpenProfile(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseProfile = () => setOpenProfile(false);

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    if (storedName) {
      setUserName(storedName);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    window.location.href = "/authentication/sign-in";
  };


  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const renderProfile = () => (
    <Menu
      anchorEl={openProfile}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfile)}
      onClose={handleCloseProfile}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>account_circle</Icon>}
        title={userName}
      />
      <NotificationItem icon={<Icon>logout</Icon>} onClick={handleLogout} title="Logout" />
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : white.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),

      })}
    >
      <Toolbar sx={(theme) => ({ ...navbarContainer(theme), width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' })}>
        {/* <Link to={currentPage}> */}
          <MDBox color="white" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            {currentPage}
            {/* <Breadcrumbs icon="home" title={route.join(" / ")} route={route} light={light} /> */}
          </MDBox>
        {/* </Link> */}


        {/* <MDBox color="black" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard/Dashboard">
        <AiFillHome style={{ color: 'black', marginRight: '4px' }} />
        </Link> 
        /{currentPage} 
         </div> 
         
      </MDBox> */}

        {isMini ? null : (
          <MDBox sx={(theme) => ({ ...navbarRow(theme, { isMini }), width: '100%' })}>
            <MDBox
              color={light ? "white" : "white"}
              sx={{ marginLeft: 'auto' }}
            >
              {/* <Link to="">
                <IconButton color="white" sx={{ ...navbarIconButton, margin: '0 1px', color: 'white' }} size="small" disableRipple>
                  <Icon sx={{ ...iconsStyle, color: 'white' }}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ ...navbarIconButton, ...navbarMobileMenu, margin: '0 1px' }}
                onClick={handleMiniSidenav}
              >
                <Icon sx={{ ...iconsStyle, color: 'white' }} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ ...navbarIconButton, margin: '0 1px' }}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={{ ...iconsStyle, color: 'white' }}>notifications</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ ...navbarIconButton, margin: '0 1px' }}
                onClick={handleLogout}
              >
                <Icon sx={{ ...iconsStyle, color: 'white' }}>logout</Icon>
              </IconButton> */}
              <Link to="">
                <IconButton color="white" onClick={handleOpenProfile} sx={{ ...navbarIconButton, margin: '0 1px', color: 'white' }} size="small" disableRipple>
                  <Icon sx={{ ...iconsStyle, color: 'white' }}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ ...navbarIconButton, ...navbarMobileMenu, margin: '0 1px' }}
                onClick={handleMiniSidenav}
              >
                <Icon sx={{ ...iconsStyle, color: 'white' }} fontSize="medium">
                  {miniSidenav ? "menu" : " menu_open "}
                </Icon>
              </IconButton>
              {renderMenu()}
              {renderProfile()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>

      
    </AppBar>

  );
}

// DashboardNavbar.defaultProps = {
//   absolute: false,
//   light: false,
//   isMini: false,
// };

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

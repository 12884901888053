
import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Card,
    CardContent,
    Grid,
    Container,
    IconButton,
    Box,
    Pagination
} from '@mui/material';
import MDBox from "../../components/MDBox";
import { MdHistory } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const ShippedProduct = () => {
    const [centers, setCenters] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchCenters = async () => {
        try {
            const response = await api.get('/CenterList' , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
            setCenters(response.data);
        } catch (error) {
            console.error('Error fetching centers:', error);
        }
    };

    useEffect(() => {
        fetchCenters();
    }, []);

    const handleViewVendorProduct = (centerId) => {
        navigate(`/shippedProductlist?centerId=${centerId}` , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
    };

    const pageCount = Math.ceil(centers.length / itemsPerPage);
    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="p-3">
                {/* <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <Typography variant="h5" style={{ color: 'white' }} component="h2">
                        Shipped Product Center Management
                    </Typography>
                </MDBox> */}
            </div>
            <br></br>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
                        <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Name</TableCell>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
                        </TableRow>
                        {centers.map((center,index) => (
                            <TableRow key={center.id}>
                                <TableCell style={{ fontWeight: '500' ,width:'15%'}}>{(currentPage - 1)* itemsPerPage + index+1}.</TableCell>
                                <TableCell style={{fontWeight:'500'}}>{center.name}</TableCell>
                                <TableCell>
                                    <Tooltip title="List of Order Data">
                                        <IconButton
                                        size='medium'
                                            style={{ color: 'black' }}
                                            onClick={() => handleViewVendorProduct(center.id)}
                                        >
                                            <MdHistory />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {pageCount > 1 && (
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handleChangePage}
                            color='primary'
                            sx={{
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    color: '#fff',
                                    background: 'linear-gradient(195deg, #42424a, #191919)',
                                },
                            }}
                        />
                    )}
                </Box>

        </DashboardLayout>
    );
};

export default ShippedProduct;
import React, { useState, useEffect } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import api from '../../service/api';
import Footer from "layouts/authentication/components/Footer";

const Role = () => {
    const [formData, setFormData] = useState({
        roleName: '',
    });

    const [errors, setErrors] = useState({
        roleName: '',
    });

    const [roles, setRoles] = useState([]);
    const [operationFlag, setOperationFlag] = useState(0);
    const [editRoleId, setEditRoleId] = useState(null);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await api.get('/GetRole' , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
            setRoles(response.filter);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleSignIn = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        const newErrors = { ...errors };

        if (!formData.roleName) {
            newErrors.roleName = 'Role Name is required';
            formIsValid = false;
        } else {
            newErrors.roleName = '';
        }

        setErrors(newErrors);

        if (formIsValid) {
            try {
                let response;
                if (operationFlag === 0) {
                    response = await api.post(`/AddEditRole?flag=${operationFlag}`, formData , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
                    console.log('Role added successfully:', response.data);
                } else if (operationFlag === 1) {
                    response = await api.post(`/AddEditRole?flag=${operationFlag}`, formData , {headers:{"Authorization":`Bearer ${localStorage.getItem("token")}`}});
                    console.log('Role updated successfully:', response.data);
                }
                setOperationFlag(0);
                fetchRoles(); // Refresh roles list
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditRole = (roleId) => {
        const roleToEdit = roles.find(role => role.id === roleId);

        if (roleToEdit) {
            setFormData({
                id: roleToEdit.id,
                roleName: roleToEdit.roleName,
            });
            setEditRoleId(roleId);
            setOperationFlag(1);
        } else {
            console.error("Role not found for editing");
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div>
                <h2 style={{ textAlign: 'center'  }}>Add Role</h2>
                <form onSubmit={handleSignIn} style={{ marginBottom: '20px' }}>
                    <TextField
                        label="Role Name"
                        name="roleName"
                        value={formData.roleName}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.roleName}
                        helperText={errors.roleName}
                        style={{ marginBottom: '20px'  }}
                    />
                    <Button variant="contained" color="primary" style={{ color: 'white'}} type="submit">
                        {operationFlag === 1 ? 'Update Role' : 'Add Role'}
                    </Button>
                </form>
                <h2 style={{ textAlign: 'center' }}>Roles</h2>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>                       
                            <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
                                <TableCell style={{ fontWeight: '500' }}>Role Name</TableCell>
                                <TableCell style={{ fontWeight: '500' }}>Action</TableCell>
                            </TableRow>

                            {roles
                            .filter(role => role.roleName !== 'Owner') // Filter out 'Owner' role
                            .map(role => (
                                <TableRow key={role.id}>
                                    <TableCell>{role.roleName}</TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={() => handleEditRole(role.id)}>Edit</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/* <Footer dark /> */}
        </DashboardLayout>
    );
};

export default Role;

import React, { useState, useEffect } from 'react';
import {
  FormControl, Button, Box, TextField, Table, TableBody, TableCell, TableContainer,
  TableRow, Paper, Pagination, MenuItem, Select, AppBar, Grid, Tabs, Tab,
  useMediaQuery, useTheme, InputAdornment, InputLabel, Typography, IconButton, Card,
  Modal,
  TableHead,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from '@mui/material/Icon';
import SearchIcon from '@mui/icons-material/Search';
import MDBox from "../../components/MDBox"
import { GrClearOption } from "react-icons/gr";
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BiPlusCircle, BiEdit } from 'react-icons/bi';
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import Cookies from 'js-cookie';

const Vendor = () => {
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);
  const [operationFlag, setOperationFlag] = useState(0);
  const [editVendorId, setEditVendorId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [tempSelectedUsers, setTempSelectedUsers] = useState({});
  const [sortOrder, setSortOrder] = useState('default');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filterStatus, setFilterStatus] = useState('');
  const [tabValue, setTabValue] = React.useState(0);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    vendorName: '',
    mobile_Number:  0.0,
    address: ''
  });

  const [errors, setErrors] = useState({
    vendorName: '',
    mobile_Number:'',
    address: '',
  });

  useEffect(() => {
    async function fetchVendors() {
      try {
        const response = await api.get('/GetVendor', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        setVendors(response.data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    }
    fetchVendors();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await api.get('/GetUsers', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }
    fetchUsers();
  }, []);

  const handleTempUserChange = (vendorId, userId) => {
    setTempSelectedUsers(prevState => ({
      ...prevState,
      [vendorId]: userId
    }));
  };

  const handleSaveUser = (vendorId) => {
    setSelectedUsers(prevState => ({
      ...prevState,
      [vendorId]: tempSelectedUsers[vendorId]
    }));
    setVendors(prevVendors =>
      prevVendors.map(vendor =>
        vendor.id === vendorId ? { ...vendor, userId: tempSelectedUsers[vendorId] } : vendor
      )
    );
  };

  const handleConfirmOperation = async (vendorId, userId, operationFlag) => {
    try {
      if (operationFlag) {
        if (tempSelectedUsers[vendorId] !== '') {
          const response = await api.post(`/AddEditVendor?flag=${operationFlag}`, { id: vendorId, userId: tempSelectedUsers[vendorId] }, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
          console.log('Vendor allocated successfully:', response.data);
          toast.success('User allocated successfully');
          handleSaveUser(vendorId);
        }

      } else {
        const response = await api.post(`/DeallocateDasher?id=${vendorId}`,
          {}, // Pass an empty object for the request body if there is no body
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
          });
        console.log('Vendor deallocated successfully:', response.data);
        setSelectedUsers(prevState => {
          const updatedUsers = { ...prevState };
          delete updatedUsers[vendorId];
          return updatedUsers;
        });
        setVendors(prevVendors =>
          prevVendors.map(vendor =>
            vendor.id === vendorId ? { ...vendor, userId: null } : vendor
          )
        );
        toast.success('User deallocated successfully');
        setTempSelectedUsers(prevTempSelectedUsers => {
          const updatedTempSelectedUsers = { ...prevTempSelectedUsers };
          delete updatedTempSelectedUsers[vendorId];
          return updatedTempSelectedUsers;
        });
      }
      const response = await api.get('/GetVendor', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
      setVendors(response.data);
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Failed to ${operationFlag ? 'allocate' : 'deallocate'} user`);
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  useEffect(() => {
    // Retrieve cookie value on component mount
    const savedQuery = Cookies.get('searchVendor');
    if (savedQuery) {
      setSearchQuery(savedQuery);
    }
  }, []);

  const handleSearchChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    // Set cookie with the new search query
    Cookies.set('searchVendor', newSearchQuery, { expires: 7 }); // Expires in 7 days
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const savedFilterStatus = Cookies.get('filterStatus');
    if (savedFilterStatus) {
      setFilterStatus(savedFilterStatus);
    }
  }, []);

  const handleFilterChange = (value) => {
    setFilterStatus(value);
    Cookies.set('filterStatus', value, { expires: 7 }); // Cookie expires in 7 days
  };

  const clearSortOrder = () => {
    setFilterStatus("");
    setSearchQuery('');
    Cookies.remove('filterStatus'); // Clear the cookie
    Cookies.remove('searchVendor'); // Clear the cookie
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredAndSortedVendors = vendors
    .filter(vendor => (
      vendor.vendorName && vendor.vendorName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      vendor.userName && vendor.userName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      vendor.mobile_Number && vendor.mobile_Number.toString().includes(searchQuery.toString()) ||
      vendor.address && vendor.address.toLowerCase().toString().includes(searchQuery.toLowerCase().toString())

    ))
    .filter(vendor => {
      if (filterStatus === 'allocated') return vendor.userId !== null;
      if (filterStatus === 'not-allocated') return vendor.userId === null;
      return true;
    });

  // const paginatedData = filteredAndSortedVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // // Calculate the total number of pages
  // const pageCount = Math.ceil(filteredAndSortedVendors.length / itemsPerPage);
  // Update current page if it exceeds the number of pages after filtering
  const pageCount = Math.ceil(filteredAndSortedVendors.length / itemsPerPage);
  if (currentPage > pageCount && pageCount > 0) {
    setCurrentPage(pageCount);
  }

  // Get the vendors for the current page
  const paginatedData = filteredAndSortedVendors.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  // Pagination calculations
  const indexOfLastVendor = currentPage * itemsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - itemsPerPage;


  const handleSignIn = async (event) => {
    event.preventDefault();

        // Check if mobile_Number is empty and set it to 0
        const dataToSubmit = {
          ...formData,
          mobile_Number: formData.mobile_Number === '' ? 0 : formData.mobile_Number
      };
  
    let formIsValid = true;
    const newErrors = { ...errors };

    // Validate form fields
    if (!formData.vendorName) {
      newErrors.vendorName = 'Name is required';
      formIsValid = false;
    } else {
      newErrors.vendorName = '';
    }

    // // Check if mobile_Number is empty and set to null if it is
    // if (!formData.mobile_Number) {
    //   newErrors.mobile_Number = 'Mobile Number is required';
    //   formIsValid = false;
    //   formData.mobile_Number = null; // Set phoneNo to null
    // } else {
    //   newErrors.mobile_Number = '';
    // }

    // // Validate address
    // if (!formData.address) {
    //   newErrors.address = 'Address is required';
    //   formIsValid = false;
    // } else {
    //   newErrors.address = '';
    // }

    setErrors(newErrors);

    if (formIsValid) {
      try {
        let response;
        if (operationFlag === 0) {
          response = await api.post(`/AddEditVendor?flag=${operationFlag}`, dataToSubmit, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
          console.log('Product added successfully:', response.data);
          toast.success('Vendor added successfully!');
        } else if (operationFlag === 1) {
          response = await api.post(`/AddEditVendor?flag=${operationFlag}`, formData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
          console.log('Product updated successfully:', response.data);
          toast.success('Vendor updated successfully!');
        }
        setOperationFlag(0); // Reset to add mode
        setOpenModal(false); // Close modal after submission
        const responsev = await api.get('/GetVendor', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        setVendors(responsev.data);
      } catch (error) {
        toast.error('Error occurred while processing your request.');
        console.error('Error:', error);
      }
    }

  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'mobile_Number' 
        ? (value ===  '' ? 0 : Math.floor(parseFloat(value))) // Set to 0.0 if empty and format to one decimal place
        : value
    }));
  };

  const handleEditVendor = (VendorId) => {
    // Find the Vendor by its ID
    const VendorToEdit = vendors.find(Vendor => Vendor.id === VendorId);

    // If the Vendor is found, populate the form data with its details
    if (VendorToEdit) {
      setFormData({
        id: VendorToEdit.id,
        vendorName: VendorToEdit.vendorName, // Make sure property names match
        mobile_Number: VendorToEdit.mobile_Number, // Make sure property names match
        address: VendorToEdit.address, // Make sure property names match
      });

      // Set the ID of the Vendor being edited
      setEditVendorId(VendorId);
      setOperationFlag(1);
      setOpenModal(true); // Open the modal for editing
    } else {
      console.error("Vendor not found for editing");
    }
  };

  const handleOpenModal = () => {
    resetForm();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    resetForm();
    setOpenModal(false);
  };

  const resetForm = () => {
    setFormData({
      vendorName: '',
      mobile_Number: '',
      address: '',
    });
    setErrors({
      vendorName: '',
      mobile_Number: '',
      address: '',
    });
    setOperationFlag(0);
    setEditVendorId(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h5" style={{ color: 'white' }} component="h2">
          Vendor Management
        </Typography>
      </MDBox> */}
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
        <TextField
          variant="outlined"
          size="medium"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth={isMobile}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: '20px' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: isMobile ? '100%' : 'auto',
            maxWidth: 240,
            margin: isMobile ? '8px 0' : '4px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.375rem',
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45',
              },
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.2rem',
            },
          }}
        />
        <FormControl
          sx={{
            width: isMobile ? '100%' : 'auto',
            maxWidth: 240,
            margin: isMobile ? '8px 0' : '4px',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45', // Outline color when focused
              },
            },
          }}
        >
          <InputLabel id="filter-label" style={{ color: '#3e3e45' }}>Filter by</InputLabel>
          <Select
            labelId="filter-label"
            value={filterStatus}
            label="Sort By"
            onChange={(event) => handleFilterChange(event.target.value)}
            sx={{
              width: isMobile ? '100%' : '155px',
              borderRadius: '0.375rem',
              padding: '12px 0px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                borderColor: '#3e3e45'
              },
            }}
          >
            <MenuItem value="">
              <em>Filter by</em>
            </MenuItem>
            <MenuItem value="allocated">Allocated</MenuItem>
            <MenuItem value="not-allocated">Not Allocated</MenuItem>
          </Select>
        </FormControl>


        <Button
          variant="contained"
          style={{ color: 'white', backgroundColor: '#3e3e45', fontWeight: '400', textTransform: 'none', marginLeft: '4px' }}
          onClick={handleOpenModal}
        >
          Add Vendor
        </Button>
        <Tooltip title="Clear">
          <Button
            onClick={clearSortOrder}
            variant="contained"
            style={{ color: 'white', backgroundColor: '#3e3e45' }}
            sx={{
              marginLeft: isMobile ? '0' : 'auto',
              marginTop: isMobile ? '10px' : '0',
              marginBottom: isMobile ? '10px' : '0',
              width: isMobile ? '40%' : 'auto',
            }}
          >
            <GrClearOption style={{ fontSize: '20px' }} />
          </Button>
        </Tooltip>

      </Box>




      {isMobile ? (
        <Grid container spacing={2}>
          {paginatedData.map(vendor => (
            <Grid item xs={12} key={vendor.id}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Box style={{ fontWeight: "400", fontSize: '15px' }}>Vendor Name: {vendor.vendorName}</Box>
                <Box style={{ fontWeight: "400", fontSize: '15px' }}>User:
                  <FormControl variant="standard">
                    <Select
                      value={tempSelectedUsers[vendor.id] || vendor.userId || ''}
                      onChange={(e) => handleTempUserChange(vendor.id, e.target.value)}
                      disableUnderline
                      IconComponent={(props) => (
                        <ArrowDropDownIcon {...props} style={{ color: 'black', fontSize: '1rem', display: 'flex', alignItems: 'center' }} />
                      )}
                      style={{ fontWeight: '400', marginLeft: 'auto', fontSize: '15px', color: 'black' }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            color: 'black',
                          },
                        },
                      }}
                    >
                      <MenuItem value="">Select User</MenuItem>
                      {users.filter(user => user.isActive === true).map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl></Box>
                <Box style={{ fontWeight: "400", fontSize: '15px' }}>Phone Number : {vendor.mobile_Number}</Box>
                <Box style={{ fontWeight: "400", fontSize: '15px' }}>Address : {vendor.address}</Box>
                <Box sx={{ fontWeight: '400', fontSize: '15px' }}>Allocate/Deallocate:</Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  {vendor.userId ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 0)}
                        sx={{
                          color: 'red',
                          borderColor: 'red',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)', // light red background
                            borderColor: 'red',
                          },
                        }}
                      >
                        Deallocate
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 1)}
                        disabled={!tempSelectedUsers[vendor.id]}
                        sx={{
                          color: 'green',
                          borderColor: 'green',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 128, 0, 0.1)', // light green background
                            borderColor: 'green',
                          },
                        }}
                      >
                        Allocate
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 1)}
                      disabled={!tempSelectedUsers[vendor.id]}
                      sx={{
                        color: 'green',
                        borderColor: 'green',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 128, 0, 0.1)', // light green background
                          borderColor: 'green',
                        },
                      }}
                    >
                      Allocate
                    </Button>
                  )}
                </Box>
                <Tooltip title="Edit Vendor">
                  <Box sx={{ fontWeight: '400', fontSize: '15px', mt: 1 }}>Action:
                    <IconButton style={{ marginLeft: 'auto', color: '#13a531' }} onClick={() => handleEditVendor(vendor.id)}>
                      <BiEdit />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Paper>
            </Grid>
          ))}

        </Grid>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 700, overflow: 'auto', scrollbarWidth: 'none' }}>
          <Table stickyHeader>
            <TableBody>
              <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                <TableCell style={{ fontWeight: '500', color: 'white' }}>Vendor Name</TableCell>
                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>User</TableCell>
                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Phone</TableCell>
                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Address</TableCell>
                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Allocate/Deallocate</TableCell>
                <TableCell align='center' style={{ fontWeight: '500', color: 'white' }}>Action</TableCell>
              </TableRow>
              {paginatedData.map((vendor, index) => (
                <TableRow key={vendor.id}>
                  <TableCell style={{ fontWeight: "500" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                  <TableCell style={{ fontWeight: "500" }}>{vendor.vendorName}</TableCell>
                  <TableCell align="center">
                    <FormControl variant="standard">
                      <Select
                        value={tempSelectedUsers[vendor.id] || vendor.userId || ''}
                        onChange={(e) => handleTempUserChange(vendor.id, e.target.value)}
                        disableUnderline
                        IconComponent={(props) => (
                          <ArrowDropDownIcon {...props} style={{ color: 'black', fontSize: '1rem', display: 'flex', alignItems: 'center' }} />
                        )}
                        style={{ fontWeight: '500', color: 'black' }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              color: 'black',
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select User</MenuItem>
                        {users.filter(user => user.isActive === true).map(user => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "500" }}>{vendor.mobile_Number}</TableCell>
                  <TableCell align="center" style={{ fontWeight: "500" }}>{vendor.address}</TableCell>
                  <TableCell align='center'>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {vendor.userId ? (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 0)}
                            sx={{
                              color: 'red',
                              marginRight: '10px',
                              borderColor: 'red',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)', // light red background
                                borderColor: 'red',
                              },
                            }}
                          >
                            Deallocate
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 1)}
                            disabled={!tempSelectedUsers[vendor.id]}
                            sx={{
                              color: 'green',
                              borderColor: 'green',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 128, 0, 0.1)', // light green background
                                borderColor: 'green',
                              },
                            }}
                          >
                            Allocate
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => handleConfirmOperation(vendor.id, vendor.userId, 1)}
                          disabled={!tempSelectedUsers[vendor.id]}
                          sx={{
                            color: 'green',
                            borderColor: 'green',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 128, 0, 0.1)', // light green background
                              borderColor: 'green',
                            },
                          }}
                        >
                          Allocate
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                  <Tooltip title="Edit Vendor">
                    <TableCell align='center'>
                      <IconButton style={{ color: '#13a531' }} onClick={() => handleEditVendor(vendor.id)}>
                        <BiEdit />
                      </IconButton>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          style={{ padding: '20px', margin: '100px auto', maxWidth: isMobile ? '90%' : '600px', maxHeight: isMobile ? '80vh' : '90vh', overflowY: 'auto' }}
        >

          <Typography variant="h5" style={{ color: 'black' }} align="center">
            {operationFlag === 0 ? 'Add Vendor' : 'Edit Vendor'}
          </Typography>
          <form onSubmit={handleSignIn}>
            <TextField
              fullWidth
              label="Name"
              name="vendorName"
              value={formData.vendorName}
              onChange={handleInputChange}
              required
              error={!!errors.vendorName}
              helperText={errors.vendorName}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              type='number'
              //type="tel"
              label="Phone Number"
              name="mobile_Number"
              value={formData.mobile_Number}
              step="1" // Prevents decimal input
              onChange={(e) => {
                // Only allow digits (0-9)
                const regex = /^[0-9\b]+$/;
                if (e.target.value === '' || regex.test(e.target.value)) {
                  handleInputChange(e);
                }
              }}
              // error={!!errors.mobile_Number}
              // helperText={errors.mobile_Number}
              variant="outlined"
              margin="normal"
              InputProps={{
                inputProps: {
                  style: { MozAppearance: 'textfield' }  // For Firefox
                },
                style: {
                  WebkitAppearance: 'none',  // For Chrome, Safari, Edge, Opera
                  MozAppearance: 'textfield', // For Firefox
                }
              }}
            />

            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              // error={!!errors.address}
              // helperText={errors.address}
              variant="outlined"
              margin="normal"
              multiline
              rows={4} // Adjust the number of visible rows as needed
            />


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', fontWeight: '400', textTransform: 'none' }} type="submit">
                {operationFlag === 1 ? 'Update Vendor' : 'Add Vendor'}
              </Button>
            </div>
          </form>

        </Card>
      </Modal>
      {/* <Pagination
            rowsPerPageOptions={[8, 16, 24]}
            component="div"
            count={filteredAndSortedVendors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

        {pageCount > 1 && (
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
            color='primary'
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                color: '#fff',
                background: 'linear-gradient(195deg, #42424a, #191919)',
              },
            }}
          />
        )}
      </Box>

      <ToastContainer
        style={{
          position: isMobile ? 'top-right' : 'top-right',
          width: isMobile ? '80%' : '25%',
          fontSize: isMobile ? '12px' : '15px'
        }}
      />
      {/* <Footer dark /> */}

    </DashboardLayout>
  );
};

export default Vendor;
import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { BiEdit } from 'react-icons/bi';
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Card,
    Modal,
    IconButton,
    Box,
    Pagination,
    InputAdornment,
    Tooltip,
    useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material/styles';
import { LuFilterX } from "react-icons/lu";
import { ToastContainer, toast } from 'react-toastify';
import Footer from "layouts/authentication/components/Footer";

const Currency = () => {
    const [formData, setFormData] = useState({
        rate: '',
        currencyCode: '',
        createdBy: '', // Hardcoded as per the requirement
    });

    const [errors, setErrors] = useState({
        rate: '',
        currencyCode: ''
    });

    const [currency, setCurrency] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const itemsPerPage = 10;

    const fetchCurrency = async () => {
        try {
            const response = await api.get('/GetCurrencyConversionRateList', { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
            setCurrency(response.data);
        } catch (error) {
            console.error('Error fetching Currency:', error);
        }
    };

    useEffect(() => {
        fetchCurrency();
    }, []);

    const handleAddCurrency = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        const newErrors = { ...errors };

        // Validate form fields
        if (!formData.rate) {
            newErrors.rate = 'Rate is required';
            formIsValid = false;
        } else {
            newErrors.rate = '';
        }

        if (!formData.currencyCode) {
            newErrors.currencyCode = 'Currency Code is required';
            formIsValid = false;
        } else {
            newErrors.currencyCode = '';
        }

        setErrors(newErrors);

        if (formIsValid) {
            try {
                const response = await api.post('/AddCurrencyConversionRate', formData, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
                console.log('Currency added successfully:', response.data);
                const { message } = response.data;
                toast.success(`${message}`);
            } catch (error) {
                console.error('Error:', error);
            }
            setOpenModal(false);
            fetchCurrency();
        }

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleOpenModal = () => {
        resetForm();
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        resetForm();
        setOpenModal(false);
    };

    const resetForm = () => {
        setFormData({
            rate: '',
            currencyCode: '',
            createdBy: '895D4989-D30F-46F4-982F-3B6B3B68C1F2',
        });
        setErrors({
            rate: '',
            currencyCode: '',
        });
    };

    useEffect(() => {
        // Retrieve cookie value on component mount
        const savedQuery = Cookies.get('searchCurrency');
        if (savedQuery) {
            setSearchTerm(savedQuery);
        }
    }, []);

    const handleSearchTermChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchTerm(newSearchQuery);
        // Set cookie with the new search query
        Cookies.set('searchCurrency', newSearchQuery, { expires: 7 }); // Expires in 7 days
    };

    // const handleSearchTermChange = (event) => {
    //     setSearchTerm(event.target.value);
    // };

    const filteredCurrency = currency.filter(currency =>
        currency.currencyCode.toLowerCase().toString().includes(searchTerm.toLowerCase().toString())
    );

    const pageCount = Math.ceil(filteredCurrency.length / itemsPerPage);
    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    const clearSortOrder = () => {
        setSearchTerm('');
        Cookies.remove('searchCurrency'); // Clear the cookie
    };
    return (
        <DashboardLayout>
            <ToastContainer
                style={{
                    position: isMobile ? 'top-right' : 'top-right',
                    width: isMobile ? '80%' : '25%',
                    fontSize: isMobile ? '12px' : '15px'
                }}
            />
            <DashboardNavbar />
            <div className="p-3">
                <div className="d-flex justify-content-between" style={{ margin: '15px' }}>
                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            value={searchTerm}
                            size="medium"
                            onChange={handleSearchTermChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{ fontSize: '20px' }}>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                width: '100%',
                                maxWidth: 250,
                                marginRight: '10px',
                                marginBottom: '5px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0.375rem',
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3e3e45',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '1rem',
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3e3e45', fontWeight: '400', marginBottom: '10px', textTransform: 'none' }}
                            onClick={handleOpenModal}
                        >
                            Add/Edit Currency
                        </Button>
                        <Tooltip title="Clear">
                            <Button
                                onClick={clearSortOrder}
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#3e3e45' }}
                                sx={{
                                    marginLeft: isMobile ? '0' : 'auto',
                                    marginTop: isMobile ? '10px' : '0',
                                    marginBottom: isMobile ? '10px' : '0',
                                    width: isMobile ? '40%' : 'auto',
                                }}
                            >
                                <LuFilterX style={{ fontSize: '20px' }} />
                            </Button>
                        </Tooltip>

                    </div>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow sx={{ backgroundColor: '#3e3e45', color: 'white' }}>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Sr No</TableCell>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Currency Code</TableCell>
                            <TableCell style={{ fontWeight: '500', color: 'white' }}>Rate (INR)</TableCell>
                            {/* <TableCell style={{ fontWeight: '500', color: 'white' }}>Action</TableCell> */}
                        </TableRow>
                        {filteredCurrency.map((currency, index) => (
                            <TableRow key={currency.id}>
                                <TableCell style={{ fontWeight: "500", width: '15%' }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                                <TableCell style={{ fontWeight: '500' }}>{currency.currencyCode}</TableCell>
                                <TableCell style={{ fontWeight: '500' }}>{currency.rate}</TableCell>
                                {/* <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            style={{ color: '#13a531', alignContent: 'center' }}
                                            onClick={() => handleEditCurrency(currency.id)}
                                        >
                                            <BiEdit />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {pageCount > 1 && (
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color='primary'
                        sx={{
                            '& .MuiPaginationItem-root.Mui-selected': {
                                color: '#fff',
                                background: 'linear-gradient(195deg, #42424a, #191919)',
                            },
                        }}
                    />
                )}
            </Box> */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card style={{ padding: '20px', margin: '100px auto', maxWidth: '600px' }}>
                    <Typography variant="h5" align="center">
                        Add/Edit Currency
                    </Typography>
                    <form onSubmit={handleAddCurrency}>
                        <TextField
                            fullWidth
                            label="Currency Code"
                            name="currencyCode"
                            value={formData.currencyCode}
                            onChange={handleInputChange}
                            error={!!errors.currencyCode}
                            helperText={errors.currencyCode}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Rate(INR)"
                            name="rate"
                            value={formData.rate}
                            onChange={handleInputChange}
                            error={!!errors.rate}
                            helperText={errors.rate}
                            variant="outlined"
                            margin="normal"
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', textTransform: 'none' }} type="submit">
                            Add/Edit Currency
                            </Button>
                        </div>
                    </form>
                </Card>
            </Modal>
        </DashboardLayout>
    );
};

export default Currency;

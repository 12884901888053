import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

// Ensure you have the necessary chart.js components registered
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OrderChart = ({ data }) => {
  const chartData = {
    labels: data.map(item => item.orderName,item => item.orderDate),
    datasets: [
      {
        label: 'Total Sales',
        data: data.map(item => (item.orderPrice - (item.totalDiscount + item.totalRefund))),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
      {
        label: 'COGS',
        data: data.map(item => item.purchasePrice),
        backgroundColor: 'rgba(255,99,132,0.6)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

// Define prop types for OrderChart
OrderChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      orderName: PropTypes.string.isRequired,
      orderDate: PropTypes.string.isRequired,
      orderPrice: PropTypes.number.isRequired,
      purchasePrice: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default OrderChart;
